import { PLAN_SUCCESS } from "../contexts/actions/planActions";
import { LOGOUT_REQUEST } from "../contexts/actions/userActions";
import store from "../contexts/store";
import { authApiCall } from "./apiCalls";


const getAllPlans = (): Promise<any> => {
	return new Promise((resolve, reject) => {
		authApiCall.get("/stripe/plans")
			.then(({ data, status }) => {
				resolve(data);
			})
			.catch((error) => {
				if (error.response) {
                    const serverError = error.response.data;
                    if(serverError.statusCode === 403) {
                        reject('not logged');
                        store.dispatch({type: LOGOUT_REQUEST.type});
                    }
                    reject(serverError.message);
                } else if (error.request) {
                    reject('Server not responds.');
                } else {
                    console.log('PlanApi.getAllPlans error', error.message);
                }
			})
	});
}

const getAllProducts = (): Promise<any> => {
	return new Promise((resolve, reject) => {
		authApiCall.get("/products")
			.then(({ data, status }) => {
				resolve(data);
			})
			.catch((error) => {
				if (error.response) {
                    const serverError = error.response.data;
                    if(serverError.statusCode === 403) {
                        reject('not logged');
                        store.dispatch({type: LOGOUT_REQUEST.type});
                    }
                    reject(serverError.message);
                } else if (error.request) {
                    reject('Server not responds.');
                } else {
                    console.log('PlanApi.getAllPlans error', error.message);
                }
			})
	});
}

const payProduct = (stripeId: string): Promise<any> => {
    const params = {
        stripeId
    };

	return new Promise((resolve, reject) => {
		authApiCall.post("/products/create-checkout-session", params)
			.then(({ data, status }) => {
				resolve(data);
			})
			.catch((error) => {
				if (error.response) {
                    const serverError = error.response.data;
                    if(serverError.statusCode === 403) {
                        reject('not logged');
                        store.dispatch({type: LOGOUT_REQUEST.type});
                    }
                    reject(serverError.message);
                } else if (error.request) {
                    reject('Server not responds.');
                } else {
                    console.log('PlanApi.pay error', error.message);
                }
			})
	});
}

const pay = (stripeId: string): Promise<any> => {
    const params = {
        stripeId,
        code: 1
    };

	return new Promise((resolve, reject) => {
		authApiCall.post("/stripe/create-checkout-session", params)
			.then(({ data, status }) => {
				resolve(data);
			})
			.catch((error) => { reject(processError(error)) })
	});
}


const modifyPayment = (): Promise<any> => {

	return new Promise((resolve, reject) => {
		authApiCall.post("/stripe/modify-payment")
			.then(({ data, status }) => {
				resolve(data);
			})
			.catch((error) => { reject(processError(error)) })
	});
}

const changePlan = (stripeId: string): Promise<any> => {
    const params = {
        stripeId
    };

	return new Promise((resolve, reject) => {
		authApiCall.post("/stripe/modify-subscription", params)
			.then(({ data, status }) => {
				resolve(data);
			})
			.catch((error) => { reject(processError(error)) })
	});
}

const changePlanLeadsLimit = (stripeId: string, leadsLimit: number): Promise<any> => {
    const params = {
        id: stripeId,
        leadsLimit
    };

	return new Promise((resolve, reject) => {
		authApiCall.put("/stripe/price", params)
			.then(({ data, status }) => {
				resolve(data);
			})
			.catch((error) => { reject(processError(error)) })
	});
}

const getActualPlan = (): Promise<any> => {
	return new Promise((resolve, reject) => {
		authApiCall.get("/stripe/subscription")
			.then(({ data, status }) => {
                store.dispatch({type: PLAN_SUCCESS.type, payload: data});
				resolve(data);
			})
			.catch((error) => { reject(processError(error)) })
	});
}

const cancelPlan = (): Promise<any> => {

	return new Promise((resolve, reject) => {
		authApiCall.delete("/stripe/subscription")
			.then(({ data, status }) => {
				resolve(data);
			})
			.catch((error) => { reject(processError(error)) })
	});
}

const removeUser = (): Promise<any> => {
	return new Promise((resolve, reject) => {
		authApiCall.delete("/user")
			.then(({ data, status }) => resolve(data))
			.catch((error) => { reject(processError(error)) })
	});
}

const reactivatePlan = (): Promise<any> => {

	return new Promise((resolve, reject) => {
		authApiCall.post("/stripe/reactivate-subscription")
			.then(({ data, status }) => {
				resolve(data);
			})
			.catch((error) => { reject(processError(error)) })
	});
}

const processError = (error: any) => {
    if (error.response) {
        const serverError = error.response.data;
        if(serverError.statusCode === 403) {
            store.dispatch({type: LOGOUT_REQUEST.type});
            return 'error.0003';
        }
		else if(serverError.statusCode === 500) {
            return 'error.0001';
        }
		else if(serverError.statusCode === 400) {
            return 'error.'+serverError.message;
        }
        return serverError.message;
    } else if (error.request) {
        return 'error.0002';
    } else {
        return 'error.0001';
    }
}

const getInvoices = (): Promise<any> => {

	return new Promise((resolve, reject) => {
		authApiCall.get("/stripe/invoices")
			.then(({ data, status }) => {
				resolve(data);
			})
			.catch((error) => { reject(processError(error)) })
	});
}


export const PlanApi = {
    getAllPlans,
    getAllProducts,
    getActualPlan,
    pay,
    payProduct,
    changePlanLeadsLimit,
    modifyPayment,
    changePlan,
    cancelPlan,
	removeUser,
    getInvoices,
	reactivatePlan
  };