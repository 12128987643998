import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { CButton, CContainer, CForm, CFormInput, CFormLabel, CRow, CSpinner } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { ToastContainer } from 'react-toastify';
import { ToastNotify } from '../components/ToastNotify';
import { UserApi } from '../api/UserApi';


const SendConfirmation = (props: any) => {

	const defaultFormValue = () => {
		return {
			email: ""
		}
	}

	const { t } = useTranslation()
	const [formData, setformData] = useState(defaultFormValue());
	const [loading, setLoading] = useState(false);
	const [messageCode, setMessageCode] = useState('');
	const [loadingSpinner , setLoadingSpinner] = useState('');

	useEffect(() => {
		loading
		? setLoadingSpinner(getSpinner)
		: setLoadingSpinner("");
	}, [loading])
	const getSpinner: any = ( <CSpinner component="span" size="sm" aria-hidden="true" color="warning" /> )

	const onChange = (e: any, fieldName: string) => {
		setformData({ ...formData, [fieldName]: e.nativeEvent.target.value });
	}

	const onSubmit = async () => {
		if ((isEmpty(formData.email)) ) {
			ToastNotify.error(t('login.error-fields-mandatory'));
		} else {
			setLoading(true);
			try {
				   const response = await UserApi.confirmationSendEmail(formData.email);
					ToastNotify.success(t('remote.'+response.code));
			} catch(error) {
				ToastNotify.error(t('remote.'+error));
				setLoading(false);
				return;
			}
			setLoading(false);
			setformData(defaultFormValue());
		}
	}

	return (
		<CContainer sm className="loginContainer">
			<div className="loginWrapper">
				<CForm className="loginForm mt-2">
					<div className="mb-3">
						<CFormLabel htmlFor="inputEmail">{t('confirmation.send-to')}</CFormLabel>
						<CFormInput onChange={(e) => onChange(e, "email")} type="email" id="inputEmail" />
					</div>
					<CRow className="mb-4">
						<ToastContainer />
					</CRow>
					<CButton onClick={() => onSubmit()} className="mb-3 submitLogin">
						{t('common.send')} &nbsp; {loadingSpinner}
					</CButton>

					<hr />
					<div className="text-center mt-1">
						<NavLink to="/login" className="registerLink">{t('register.login-here')}</NavLink>
					</div>
				</CForm>
				
			</div>
		</CContainer>
	);
}

export default SendConfirmation;
