import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CImage,
  CSidebar, 
  CSidebarBrand, 
  CSidebarNav,
  CSidebarFooter } from '@coreui/react'

import { AppSidebarNav } from './AppSidebarNav'
import { useTranslation } from 'react-i18next'
import { SessionApi } from '../../api/SessionApi';
import { logoutCall, logoutImpersonate } from '../../api/endpoints';

import logo from '../../assets/images/spotileads-horizontal.png'
import turnOff from '../../assets/images/turn-off.png'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import navigation from './_nav'
import { AppState } from '../../interfaces/Store'

const AppSidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { t } = useTranslation()
  
  const unfoldable = useSelector((state: AppState) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state: AppState) => state.sidebarShow)
  const user = useSelector((state: any) => state.auth.user)
  const warningBilling = useSelector((state: any) => state.changeState.warningBilling);

  const [navItems, setNavItems] = useState(user && user.role === 'admin' ? navigation.adminItems : navigation.userItems);

  useEffect(()=>{
    (async () => {
      try {
        let items = (user && user.role != 'admin') ? navigation.userItems : navigation.adminItems;
        if( warningBilling ) {
          items.map((item)=>{
            if(item.to === '/my-account')
              return item.badge = {color: 'danger', text: '1'};
            else return item;
          })
        } else {
          items.map((item)=>{
            if(item.to === '/my-account')
              return item.badge = null;
            else return item;
          })
        }
        setNavItems([...items]);
      } catch (error) {
        console.log('El error ? ', error)
        dispatch({ type: 'set', errorMessage: error });
        dispatch({ type: 'set', showError: true });
      }
    })()
  }, [warningBilling])

  useEffect(()=>{
    (async () => {
      try {
        let items = (user && user.role != 'admin') ? navigation.userItems : navigation.adminItems;
        setNavItems([...items]);
      } catch (error) {
        console.log('El error ? ', error)
        dispatch({ type: 'set', errorMessage: error });
        dispatch({ type: 'set', showError: true });
      }
    })()
  }, [user])

  const doLogout = async () => {
    dispatch({ type: 'billing', warningBilling: false })
    closeSession();
    await logoutCall();
  }

  const doImpersonate = async () => {
    await logoutImpersonate();
    navigate("/clients");
    window.location.reload();
  }

  const closeSession = async () => {
    const sessionId = localStorage.getItem('sessionID');
    try {
      await SessionApi.closeSession(sessionId, new Date());
    } catch (error) {
      
    }
		
    localStorage.removeItem('sessionID');
    localStorage.removeItem('sessionTSTP');
	}

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
    >
      {/* to="/" */}
      <CSidebarBrand className="d-none d-md-flex" > 
        <CImage className="sidebar-brand-full" src={logo} />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navItems} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarFooter className='text-center isLink'>
        {
          user && user.impersonating ?
            <div onClick={doImpersonate}>
              <CImage className="mb-1 align-middle" src={turnOff} alt="logout" title={t('sidebar.impersonate')}/> {t('sidebar.impersonate')}
            </div>
          :   
          <div onClick={doLogout}>
            <CImage className="mb-1 align-middle" src={turnOff} alt="logout" title={t('sidebar.close-session')}/> {t('sidebar.close-session')}
          </div>
        }
      </CSidebarFooter>
    </CSidebar>
  )
}

//React omitirá renderizar el componente y reusará el último resultado renderizado si tiene las mismas props
export default React.memo(AppSidebar)
