import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    CButton,
    CCol,
    CFormSelect,
    CRow,
  } from '@coreui/react'
import { LeadsApi } from '../api/LeadsApi';
import { MarcasApi } from '../api/MarcasApi';
import { DateUtils } from '../utils/DateUtils';

const EMPTY_LABEL = ''
const DEFAULT_SELECT_VALUE = '-1'

export const initialFilterValues = {
    brand : {label: EMPTY_LABEL, value: DEFAULT_SELECT_VALUE},
    model : {label: EMPTY_LABEL, value: DEFAULT_SELECT_VALUE},
    segment : {label: EMPTY_LABEL, value: DEFAULT_SELECT_VALUE},
    province : {label: EMPTY_LABEL, value: DEFAULT_SELECT_VALUE},
    date : {label: EMPTY_LABEL, value: DEFAULT_SELECT_VALUE},
    contacts : DEFAULT_SELECT_VALUE,
    favorite : false,
    failed: false
}

export function DataTableFilters ({setData, handleChange, handleReset }) {

    const { t } = useTranslation()
    const BRAND_PLACEHOLDER_TXT = t('datatable.brand')
    const PROVINCES_PLACEHOLDER_TXT = t('datatable.province')
    const MODEL_PLACEHOLDER_TXT = t('datatable.model')
    const SEGMENT_PLACEHOLDER_TXT = t('datatable.segment')
    
    //Initialize filterValues in state
    const [filterValues, setFilterValues] = useState(initialFilterValues);

    //Initialize Model options in state, because depends on Brand selection
    const [ optionsBrand, setOptionsBrand ] = useState([{label: BRAND_PLACEHOLDER_TXT, value: DEFAULT_SELECT_VALUE}]);
    const [ favoriteDisabled, setFavoriteDisabled ] = useState(false);
    const [ haveFailed, setHaveFailed ] = useState(false);
    const [ optionsProvince, setOptionsProvince ] = useState([{ label: PROVINCES_PLACEHOLDER_TXT, value: DEFAULT_SELECT_VALUE }]);
    const [ optionsModel, setOptionsModel ] = useState([{ label: MODEL_PLACEHOLDER_TXT, value: DEFAULT_SELECT_VALUE }])
    const [ optionsSegment, setOptionsSegment ] = useState([{ label: SEGMENT_PLACEHOLDER_TXT, value: DEFAULT_SELECT_VALUE }])
    const [ modelFilterDisabled, setModelFilterDisabled ] = useState(true)
    const [ apiCall, setAPiCall ] = useState(false);
    const [ checkedFavorites, setCheckedFavorites ] = useState(false);
    const [ checkedFailed, setCheckedFailed ] = useState(false);

    useEffect(() =>{
        let isMounted = true;
        (async () => {
            const apiFilters = await MarcasApi.getFilters({
                brand: filterValues.brand.value,
                model: filterValues.model.value,
                segment: filterValues.segment.value,
                province: filterValues.province.value
            });
            // Marcas cargadas inicialmente, todas para las que se muestran leads
            const allBrands = apiFilters.brand;

            // Las provincias iniciales, todas para las que se muestran leads
            const allProvinces = apiFilters.province;

            // Los segmentos iniciales, todos para los que se muestran leads
            const allSegments = apiFilters.segment;

            // Todos los leads fallidos del usuario
            const haveFailedLeads = await LeadsApi.haveFailedLeads();

            // Añadimos el campo inicial del desplegable
            allBrands.unshift({label: BRAND_PLACEHOLDER_TXT, value: DEFAULT_SELECT_VALUE})
            allProvinces.unshift({label: PROVINCES_PLACEHOLDER_TXT, value: DEFAULT_SELECT_VALUE})
            allSegments.unshift({label: SEGMENT_PLACEHOLDER_TXT, value: DEFAULT_SELECT_VALUE})

            if(isMounted) {
                setOptionsBrand(allBrands);
                setOptionsProvince(allProvinces);
                setOptionsSegment(allSegments);
                setHaveFailed(haveFailedLeads);
            }
        })()
        return () => { isMounted = false };
    }, [])
    
    useEffect(() => {
        let isMounted = true;
        (async () => {
            if(apiCall) return;
            if(!isMounted) return;
            setAPiCall(true);
            const apiFilters = await MarcasApi.getFilters({
                brand: filterValues.brand.value,
                model: filterValues.model.value,
                segment: filterValues.segment.value,
                province: filterValues.province.value
            });

            const allBrands = apiFilters.brand;
            const allModels = apiFilters.model;
            const segments = apiFilters.segment;
            const provincias = apiFilters.province;
            allBrands.unshift({label: BRAND_PLACEHOLDER_TXT, value: DEFAULT_SELECT_VALUE})
            segments.unshift({label: SEGMENT_PLACEHOLDER_TXT, value: DEFAULT_SELECT_VALUE});
            provincias.unshift({label: PROVINCES_PLACEHOLDER_TXT, value: DEFAULT_SELECT_VALUE});
            //Update Model options on rendering component, depending on Brand value
            if( filterValues.brand.value !== DEFAULT_SELECT_VALUE) {
                allModels.unshift({ label: MODEL_PLACEHOLDER_TXT, value: DEFAULT_SELECT_VALUE });
                setOptionsModel([...allModels])
                setModelFilterDisabled(false)

            } else if( filterValues.brand.value === DEFAULT_SELECT_VALUE ) {
           
                setOptionsModel([{ label: MODEL_PLACEHOLDER_TXT, value: DEFAULT_SELECT_VALUE }]);
                setFilterValues(prevState => ({
                    ...prevState,
                    model: {
                        label: EMPTY_LABEL,
                        value: DEFAULT_SELECT_VALUE
                    },
                }))
                setModelFilterDisabled(true)
            }
            setOptionsSegment([...segments]);
            setOptionsProvince([...provincias]);
            setOptionsBrand([...allBrands]);

            setAPiCall(false);
        })()
        handleChange(filterValues)
        return () => { isMounted = false };
    }, [filterValues])
    

    const showFailed = async (name, value) => {
        setFilterValues({...filterValues, [name]: value});
        if(value) {
            const failedLeads = await LeadsApi.getUserFailedLeads();
            setFavoriteDisabled(true);
            setData(failedLeads);
        } else {
            const data = await LeadsApi.getAllLeads();
            setFavoriteDisabled(false);
            setData(data);
        }
    }

    //OPTIONS
    const getYesterday = () => {
        return new Date(new Date().getTime() - 24*60*60*1000);
    }
    const getLast7Days = () => {
        return new Date(new Date().getTime() - (24*60*60*1000*7));
    }
    const today = DateUtils.formatLocaleDate(new Date(), t('common.date-locale'))
    const yesterday = DateUtils.formatLocaleDate(getYesterday(), t('common.date-locale'))
    const last7days = DateUtils.formatLocaleDate(getLast7Days(), t('common.date-locale'))
    const optionsDate = [
        { label: t('panel.register-date'), value: '0' },
        { label: t('panel.today'), value: today },
        { label: t('panel.yesterday'), value: yesterday },
        { label: t('panel.last7days'), value: last7days },
        { label: t('panel.all'), value: '0' }
    ]

    const optionsContacts = LeadsApi.getAllContacts()

    //UI methods
    const checkboxFavoritos = (e) => {
        setCheckedFavorites(!checkedFavorites);
        setFilterValues((prevState) => ({...prevState, [e.target.name]: !checkedFavorites}));
    }

    const checkboxFailed = (e) => {
        setCheckedFailed(!checkedFailed);
        showFailed(e.target.name ,e.target.checked);
    }

    const resetForm = () => {
        setFilterValues((prevState) => ({...prevState, ...initialFilterValues}))
        handleReset()
    }

    return (
        <CRow className="g-3 mb-3 mt-3">
            <CCol xs="auto">
                <CFormSelect disabled={favoriteDisabled} name="date" size="sm" options={optionsDate} value={filterValues.date.value}
                    onChange={e => setFilterValues({...filterValues, [e.target.name]: { label: e.target.options[e.target.selectedIndex].text, value : e.target.options[e.target.selectedIndex].value}})} />
            </CCol>
            <CCol xs="auto">
                <CFormSelect disabled={favoriteDisabled} name="brand" size="sm" options={optionsBrand} value={filterValues.brand.value}
                onChange={e => setFilterValues({...filterValues, [e.target.name]: { label: e.target.options[e.target.selectedIndex].text, value : e.target.options[e.target.selectedIndex].value}})} />
            </CCol>
            <CCol xs="auto">
                <CFormSelect name="model" size="sm" className="filterField" value={filterValues.model.value} disabled={modelFilterDisabled || favoriteDisabled}
                options={optionsModel} onChange={e => setFilterValues({...filterValues, [e.target.name]: { label: e.target.options[e.target.selectedIndex].text, value : e.target.options[e.target.selectedIndex].value}})} />
            </CCol>
            <CCol xs="auto">
                <CFormSelect disabled={favoriteDisabled} name="segment" size="sm" className="filterField" value={filterValues.segment.value}
                options={optionsSegment} onChange={e => setFilterValues({...filterValues, [e.target.name]: { label: e.target.options[e.target.selectedIndex].text, value : e.target.options[e.target.selectedIndex].value}})} />
            </CCol>
            <CCol xs="auto">
                <CFormSelect disabled={favoriteDisabled} name="province" size="sm" options={optionsProvince} value={filterValues.province.value}
                    onChange={e => setFilterValues({...filterValues, [e.target.name]: { label: e.target.options[e.target.selectedIndex].text, value : e.target.options[e.target.selectedIndex].value}})} />
            </CCol>
            <CCol xs="auto">
                <CFormSelect disabled={favoriteDisabled} name="contacts" size="sm" options={optionsContacts} value={filterValues.contacts}
                    onChange={e => setFilterValues({...filterValues, [e.target.name]: e.target.options[e.target.selectedIndex].value})} />
            </CCol>
            <CCol xs="auto">
                <CButton disabled={favoriteDisabled} name="favorite" id="btnFavorites" className={filterValues.favorite ? "active" : ""} 
                    onClick={(e) => checkboxFavoritos(e)}>
                    {t('panel.favorites')}
                </CButton>
                {
                haveFailed ? 
                    <CButton disabled={favoriteDisabled} name="failed" id="btnFailed" className={filterValues.failed ? "active" : ""}
                        onClick={(e) => checkboxFailed(e)}>
                        {t('panel.failed')}
                    </CButton>
                : ""
                }
            </CCol>
            <CCol xs="auto">
                <CButton type="reset" className="btnDeleteFilters" onClick={resetForm} >
                    {t('panel.clear')}
                </CButton>
            </CCol>
        </CRow>
    )
}