/**
 * Returns string date depending on locale
 * @param date Date
 * @param locale Locale string from translations "common.date-locale"
 * @returns 
 */
const formatLocaleDate = (date, locale) => {
    let d = new Date(date);
    return d.toLocaleDateString(locale)
}

/**
 * Returns string date depending on locale
 * @param seconds Time in seconds
 * @param locale Locale string from translations "common.date-locale"
 * @returns 
 */
const formatStripeDate = (seconds, locale) => {
    return new Date(seconds * 1000).toLocaleDateString(locale)
}

type CDate = {
    day: string,
    month: string,
    year: string
}
/**
 * Returns date object with month in letters
 * @param date
 * @returns 
 */
const getDate = (date):CDate => {
    let x = new Date(date);
    let d = { day: '', month: '', year: ''}
    d.day = x.getDate().toString()
    d.month = x.toLocaleString('default', { month: 'long' });
    d.year = x.getFullYear().toString();
    return d;
}

export const DateUtils = {
    formatLocaleDate,
    formatStripeDate,
    getDate,
  };