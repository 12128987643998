import store from "../contexts/store";
import { authApiCall } from "./apiCalls";
import { LOGOUT_REQUEST } from "../contexts/actions/userActions";

const createSession = (): Promise<any> => {

	return new Promise((resolve, reject) => {
		authApiCall.post("/session")
			.then(({ data, status }) => {
				resolve(data);
			})
			.catch((error) => {
				if (error.response) {
                    const serverError = error.response.data;
                    if(serverError.statusCode === 403) {
                        reject('not user');
                    }
                    reject(serverError.message);
                } else if (error.request) {
                    reject('Server not responds.');
                } else {
                    console.log('SessionApi.createSession error', error.message);
                }
			})
	});
}

const closeSession = (sessionID, sessionTSTP): Promise<any> => {

    const params = {
        sessionId: sessionID,
        sessionTstp: sessionTSTP
    };
    
	return new Promise((resolve, reject) => {
		authApiCall.patch("/session", params)
			.then(({ data, status }) => {
				resolve(data);
			})
            .catch((error) => { reject(processError(error)) })
			.catch((error) => {
				if (error.response) {
                    const serverError = error.response.data;
                    if(serverError.statusCode === 403) {
                        reject('admin dont have session');
                        // store.dispatch({type: LOGOUT_REQUEST.type});
                    }
                    reject(serverError.message);
                } else if (error.request) {
                    reject('Server not responds.');
                } else {
                    console.log('SessionApi.closeSession error', error.message);
                }
			})
	});
}

/////////////////////
// USER STATISTICS //
/////////////////////

const getSessionsCurrentPeriod = (): Promise<any> => {
    
	return new Promise((resolve, reject) => {
		authApiCall.get("/session/counter/current")
			.then(({ data, status }) => {
				resolve(data);
			})
			.catch((error) => {
				if (error.response) {
                    const serverError = error.response.data;
                    if(serverError.statusCode === 403) {
                        reject('not logged');
                        store.dispatch({type: LOGOUT_REQUEST.type});
                    }
                    reject(serverError.message);
                } else if (error.request) {
                    reject('Server not responds.');
                } else {
                    console.log('SessionApi.getSessionsCurrentPeriod error', error.message);
                }
			})
	});
}

const getSessionsLastPeriod = (): Promise<any> => {
    
	return new Promise((resolve, reject) => {
		authApiCall.get("/session/counter/last")
			.then(({ data, status }) => {
				resolve(data);
			})
			.catch((error) => {
				if (error.response) {
                    const serverError = error.response.data;
                    if(serverError.statusCode === 403) {
                        reject('not logged');
                        store.dispatch({type: LOGOUT_REQUEST.type});
                    }
                    reject(serverError.message);
                } else if (error.request) {
                    reject('Server not responds.');
                } else {
                    console.log('SessionApi.getSessionsLastPeriod error', error.message);
                }
			})
	});
}

const getAvgContactsPerDay = (): Promise<any> => {
    
	return new Promise((resolve, reject) => {
		authApiCall.get("/session/contacts")
			.then(({ data, status }) => {
				resolve(data);
			})
			.catch((error) => {
				if (error.response) {
                    const serverError = error.response.data;
                    if(serverError.statusCode === 403) {
                        reject('not logged');
                        store.dispatch({type: LOGOUT_REQUEST.type});
                    }
                    reject(serverError.message);
                } else if (error.request) {
                    reject('Server not responds.');
                } else {
                    console.log('SessionApi.getAvgContactsPerDay error', error.message);
                }
			})
	});
}

const getLoggedTime = (): Promise<any> => {
    
	return new Promise((resolve, reject) => {
		authApiCall.get("/session/hours")
			.then(({ data, status }) => {
				resolve(data);
			})
			.catch((error) => {
				if (error.response) {
                    const serverError = error.response.data;
                    if(serverError.statusCode === 403) {
                        reject('not logged');
                        store.dispatch({type: LOGOUT_REQUEST.type});
                    }
                    reject(serverError.message);
                } else if (error.request) {
                    reject('Server not responds.');
                } else {
                    console.log('SessionApi.getLoggedTime error', error.message);
                }
			})
	});
}

//////////////////////
// ADMIN STATISTICS //
//////////////////////

const getSessionsCurrentPeriodAdmin = (userId: String): Promise<any> => {
    
	return new Promise((resolve, reject) => {
        const params = {
            userId
        };
		authApiCall.get("/session/admin/counter/current", {params})
			.then(({ data, status }) => {
				resolve(data);
			})
			.catch((error) => {
				if (error.response) {
                    const serverError = error.response.data;
                    if(serverError.statusCode === 403) {
                        reject('not logged');
                        store.dispatch({type: LOGOUT_REQUEST.type});
                    }
                    reject(serverError.message);
                } else if (error.request) {
                    reject('Server not responds.');
                } else {
                    console.log('SessionApi.getSessionsCurrentPeriodAdmin error', error.message);
                }
			})
	});
}

const getSessionsLastPeriodAdmin = (userId: String): Promise<any> => {
    
	return new Promise((resolve, reject) => {
        const params = {
            userId
        };
		authApiCall.get("/session/admin/counter/last", {params})
			.then(({ data, status }) => {
				resolve(data);
			})
			.catch((error) => {
				if (error.response) {
                    const serverError = error.response.data;
                    if(serverError.statusCode === 403) {
                        reject('not logged');
                        store.dispatch({type: LOGOUT_REQUEST.type});
                    }
                    reject(serverError.message);
                } else if (error.request) {
                    reject('Server not responds.');
                } else {
                    console.log('SessionApi.getSessionsLastPeriodAdmin error', error.message);
                }
			})
	});
}

const getAvgContactsPerDayAdmin = (userId: String): Promise<any> => {
    
	return new Promise((resolve, reject) => {
        const params = {
            userId
        };
		authApiCall.get("/session/admin/contacts", {params})
			.then(({ data, status }) => {
				resolve(data);
			})
			.catch((error) => {
				if (error.response) {
                    const serverError = error.response.data;
                    if(serverError.statusCode === 403) {
                        reject('not logged');
                        store.dispatch({type: LOGOUT_REQUEST.type});
                    }
                    reject(serverError.message);
                } else if (error.request) {
                    reject('Server not responds.');
                } else {
                    console.log('SessionApi.getAvgContactsPerDayAdmin error', error.message);
                }
			})
	});
}

const getLoggedTimeAdmin = (userId: String): Promise<any> => {
    
	return new Promise((resolve, reject) => {
        const params = {
            userId
        };
		authApiCall.get("/session/admin/hours", {params})
			.then(({ data, status }) => {
				resolve(data);
			})
			.catch((error) => {
				if (error.response) {
                    const serverError = error.response.data;
                    if(serverError.statusCode === 403) {
                        reject('not logged');
                        store.dispatch({type: LOGOUT_REQUEST.type});
                    }
                    reject(serverError.message);
                } else if (error.request) {
                    reject('Server not responds.');
                } else {
                    console.log('SessionApi.getLoggedTimeAdmin error', error.message);
                }
			})
	});
}

const processError = (error: any) => {
    if (error.response) {
        const serverError = error.response.data;
        if(serverError.statusCode === 403) {
            store.dispatch({type: LOGOUT_REQUEST.type});
            return 'error.0003';
        }
		else if(serverError.statusCode === 500) {
            return 'error.0001';
        }
		else if(serverError.statusCode === 400) {
            return 'error.'+serverError.message;
        }
        return serverError.message;
    } else if (error.request) {
        return 'error.0002';
    } else {
        return 'error.0001';
    }
}

export const SessionApi = {
    createSession,
    closeSession,
    getAvgContactsPerDay,
    getAvgContactsPerDayAdmin,
    getLoggedTime,
    getLoggedTimeAdmin,
    getSessionsCurrentPeriod,
    getSessionsCurrentPeriodAdmin,
    getSessionsLastPeriod,
    getSessionsLastPeriodAdmin
};