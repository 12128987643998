import { PLAN_SUCCESS } from "../actions/planActions";

const initialState = {
  plan: null
};

// A partir del estado inicial y de la action se actualiza el estado
export function planReducer(state = initialState, action) {
  switch (action.type) {
    case PLAN_SUCCESS.type:
      return Object.assign({}, state, {
        loggedIn: true,
        plan: action.payload
      });
      
    default:
      return state
  }
}