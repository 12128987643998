import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { LeadsApi } from '../api/LeadsApi';
import DataTableMyLeads from '../components/DataTableMyLeads';
import { CAlert } from '@coreui/react';

const MyLeads = () => {

  const { t } = useTranslation()
  const user = useSelector((state: any) => state.auth.user);
  const dispatch = useDispatch();

  const [ myLeadsList, setMyLeadsList ] = useState([]);
  const [ loading, setLoading ] = useState(true);
  const [ reloadLeads, setReloadLeads ] = useState(true)
  
  useEffect(() => {
    if(reloadLeads) {
      (async () => {
        try {
          const data = await LeadsApi.getUserLeads();
          setMyLeadsList(data);
          setReloadLeads(false)
          setLoading(false);
        } catch (error) {
          dispatch({ type: 'set', errorMessage: error });
          dispatch({ type: 'set', showError: true });
        }
      })()
    }
  }, [reloadLeads])

  const buyPlan = () => {
    dispatch({ type: 'plans', showPlans: true });
  }
  
  return (
    <>
    <h1>{t('panel.my-leads')}</h1>
    { user && user.company.monthlySubscriptionStatus == "active" ?
      <DataTableMyLeads data={myLeadsList} loading={loading} setReload={setReloadLeads}/>
      :
      <CAlert color="danger" className="alertNoLeads">{t('header.no-plan')} <b className="isLink underline" onClick={buyPlan}>{t('header.buy-plan')}</b></CAlert>
    }
    </>
  )
}

export default MyLeads