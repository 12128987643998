import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CContainer } from '@coreui/react'

const Success = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    setTimeout(() => {
        navigate("/dashboard");
    }, 2500);

  return (
    <CContainer sm className="responseContainer">
        <div className="responseWrapper">
            <h1 className="text-center mb-5 responseHeader">{t('stripe-response.success-title')}</h1>
            <div className="text-center">{t('stripe-response.success-redirect')}</div>
            <div className='loader'></div>
        </div>
    </CContainer>
  )
}

export default Success