import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import DataTable from 'react-data-table-component'
import { useNavigate } from 'react-router-dom'
import { DataTableFilters } from './DataTableFilters'
import { DateUtils} from '../utils/DateUtils'
import { initialFilterValues } from './DataTableFilters'
import { ContactsRate } from './ContactsRate'
import { FavoriteCheckbox } from './FavoriteCheckbox'
import { LeadsApi } from '../api/LeadsApi'

export function AppDataTable ({ setData, data, loading, setReload }) {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const user = useSelector((state: any) => state.auth.user);

    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [filterValues, setFilterValues] = useState(initialFilterValues);

    //Check if user has a contracted plan, if not shows buy plan modal
    const userHasPlan = () => {
      if( user.plan && user.company.monthlySubscriptionStatus == "active" ) {
        return true;
      } else {
        dispatch({ type: 'plans', showPlans: true });
        return false;
      }
    }

    //Methods for handle FavoriteCheckbox child component
    const addFavoriteLead = async (id) => {
      try {
        await LeadsApi.addFavorite( id )
        setReload(true)
      } catch (error) {
        dispatch({ type: 'set', errorMessage: error });
        dispatch({ type: 'set', showError: true });
      }
    }

    const removeFavoriteLead = async (id) => {
        try {
          await LeadsApi.removeFavorite( id )
          setReload(true)
        } catch (error) {
          dispatch({ type: 'set', errorMessage: error });
          dispatch({ type: 'set', showError: true });
        }
    }

    //Methods for handle AppFilterComponent, for updating datatable content
    const handleFilterChange = (newValues) => {
      setFilterValues({...newValues})
    }

    const handleClear = () => {
      setResetPaginationToggle(!resetPaginationToggle)
    }

    //Show lead info card
    const openLeadCard = (row, event) => {
      if( userHasPlan() ) {
        navigate("/card/"+row.id)
      }
    };

    //Custom sortable methods for react-data-table-component
    const timestampSort = (rowA, rowB) => {
      const a = rowA.timestamp.toLowerCase();
      const b = rowB.timestamp.toLowerCase();
      if (a > b) {
          return 1;
      }
      if (b > a) {
          return -1;
      }
      return 0;
    };

    const caseInsensitiveNameSort = (rowA, rowB) => {
      const a = rowA.name.toLowerCase();
      const b = rowB.name.toLowerCase();
      if (a > b) {
          return 1;
      }
      if (b > a) {
          return -1;
      }
      return 0;
    };

    const caseInsensitiveSurnameSort = (rowA, rowB) => {
      const a = rowA.surname.toLowerCase();
      const b = rowB.surname.toLowerCase();
      if (a > b) {
          return 1;
      }
      if (b > a) {
          return -1;
      }
      return 0;
    };

    const caseInsensitiveBrandSort = (rowA, rowB) => {
      const a = rowA.brand.toLowerCase();
      const b = rowB.brand.toLowerCase();
      if (a > b) {
          return 1;
      }
      if (b > a) {
          return -1;
      }
      return 0;
    };

    const caseInsensitiveModelSort = (rowA, rowB) => {
      const a = rowA.model.toLowerCase();
      const b = rowB.model.toLowerCase();
      if (a > b) {
          return 1;
      }
      if (b > a) {
          return -1;
      }
      return 0;
    };
    const caseInsensitiveSegmentSort = (rowA, rowB) => {
      const a = rowA.segment.toLowerCase();
      const b = rowB.segment.toLowerCase();
      if (a > b) {
          return 1;
      }
      if (b > a) {
          return -1;
      }
      return 0;
    };

    //Const for datatable configuration
    const tableColumns = [
        {
          name: t('datatable.date'),
          selector: row => row.date,
          center: true,
          compact: true,
          sortable: true,
          sortFunction: timestampSort
        },
        {
          name: t('datatable.name'),
          selector: row => row.name,
          center: true,
          compact: true,
          sortable: true,
          sortFunction: caseInsensitiveNameSort
        },
        {
          name: t('datatable.surname'),
          selector: row => row.surname,
          center: true,
          compact: true,
          sortable: true,
          sortFunction: caseInsensitiveSurnameSort
        },
        {
          name: t('datatable.brand'),
          selector: row => row.brand,
          center: true,
          compact: true,
          sortable: true,
          sortFunction: caseInsensitiveBrandSort
        },
        {
          name: t('datatable.model'),
          selector: row => row.model,
          center: true,
          compact: true,
          sortable: true,
          sortFunction: caseInsensitiveModelSort
        },
        {
          name: t('datatable.segment'),
          selector: row => row.segment,
          center: true,
          compact: true,
          sortable: true,
          sortFunction: caseInsensitiveSegmentSort
        },
        {
          name: t('datatable.visualizations'),
          selector: row => row.visualizations,
          center: true,
          compact: true,
          sortable: true
        },
        {
          name: t('datatable.ncontacts'),
          selector: row => row.contactsNumber,
          cell: row => <ContactsRate contacts={row.contactsNumber} />,
          center: true,
          compact: true,
        },
        {
          name: t('datatable.province'),
          selector: row => row.province,
          center: true,
          compact: true,
        },
        {
          name: '',
          selector: row => row.favorite,
          cell: row => <FavoriteCheckbox id={row.id} 
                            checked={row.favorite} 
                            addFavorite={addFavoriteLead} 
                            removeFavorite={removeFavoriteLead} />,
          center: true,
          compact: true,
          minWidth: '4rem',
          maxWidth: '4rem',
        },
      ]
    
    //Format DB data to DataTable format
    const tableData = data.map((item, index) => (
        {
            id: item.id,
            date: DateUtils.formatLocaleDate(item.fechaAlta, t('common.date-locale')),
            timestamp: item.fechaAlta,
            name: item.name,
            surname: item.surname,
            brand: item.brand,
            model: item.model,
            segment: item.type,
            visualizations: item.visualizations,
            contactsNumber: item.contactsNumber,
            province: item.province,
            favorite: item.favorito,
        }
    ))

    const paginationOptions = {
      rowsPerPageText: t('datatable.page-rows'),
      rangeSeparatorText: t('datatable.range-separator'),
      selectAllRowsItem: true,
      selectAllRowsItemText: t('datatable.all-rows'),
    }

    const customStyles = {
      headCells: {
        style: {
          color: '#FFFFFF',
          backgroundColor: '#4B83C3',
        },
      },
      subHeader: {
        style: {
          alignItems: 'start',
          justifyContent: 'start',
        },
      },
    }

    //Dynamic data filtering, managed by DataTable lib
    const filteredItems = tableData.filter(item => {
        
        //If no filter criteria, add to filteredItems
        if(filterValues == null) return true;
        if(filterValues.brand.value==='-1' && filterValues.model.value==='-1' && filterValues.segment.value==='-1' 
            && filterValues.province.value==='-1' && filterValues.date.value==='-1' && filterValues.contacts==='' 
            && filterValues.favorite===false) {
          return true;
        }
        //Filter for each criteria
        if (filterValues.brand.value!=='-1') {
          if(item.brand.toLowerCase().trim() !== filterValues.brand.label.toLowerCase().trim())
            return false;
        }
        if(filterValues.model.value!=='-1') {
          if(item.model.toLowerCase().trim() !== filterValues.model.label.toLowerCase().trim())
            return false;
        }
        if(filterValues.segment.value!=='-1') {
          if(item.segment.toLowerCase().trim() !== filterValues.segment.label.toLowerCase().trim())
            return false;
        }
        if(filterValues.province.value!=='-1') {
          if(!item.province) return false;
          if(item.province && item.province.toLowerCase().trim() !== filterValues.province.label.toLowerCase().trim())
            return false;
        }
        if(filterValues.date.value!=='-1') {
          //Convert dates for comparing Date objects
          const dateParts = item.date.split("/");
          const itemDate = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
          const dateParts2 = filterValues.date.value.split("/");
          const filterDate = new Date(+dateParts2[2], +dateParts2[1] - 1, +dateParts2[0]);
          if(itemDate < filterDate)
            return false;
        }
        if (filterValues.contacts!=='-1') {
          if(item.contactsNumber > parseInt(filterValues.contacts.trim()))
            return false;
        }
        if(filterValues.favorite===true) {
          if(item.favorite !== filterValues.favorite)
            return false;
        }
        return true;
    })
  
    return (
        <>
          <DataTable
              pagination
              columns={tableColumns}
              customStyles={customStyles}
              data={filteredItems}
              highlightOnHover={true}
              onRowClicked={openLeadCard}
              paginationPerPage={25}
              paginationComponentOptions={paginationOptions}
              paginationRowsPerPageOptions={[25, 50, 100]}
              paginationResetDefaultPage={resetPaginationToggle}
              noDataComponent={<div className="pb-4">{t('datatable.no-records-to-display')}</div>}
              progressPending={loading}
              subHeader
              subHeaderComponent={<DataTableFilters 
                                    setData={setData}
                                    handleChange={handleFilterChange} 
                                    handleReset={handleClear} />}
          />
        </>
    )
}

export default AppDataTable