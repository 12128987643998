import { useEffect, useState } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { CButton, CContainer, CForm, CFormInput, CFormLabel, CImage, CRow } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { isEmpty, size } from 'lodash';
import hidePassImg from '../assets/images/hide-pass.png'
import revealPassImg from '../assets/images/reveal-pass.png'
import { ToastContainer } from 'react-toastify';
import { ToastNotify } from '../components/ToastNotify';
import { UserApi } from '../api/UserApi';


const RecovePassword = (props: any) => {

	const defaultFormValue = () => {
		return {
			password: "",
			email: ""
		}
	}

	const { t } = useTranslation()
	let [searchParams, setSearchParams] = useSearchParams();
	let [messageCode, setMessageCode] = useState('0000');

	const [showPass, setShowPass] = useState(false);
	const [token, setToken] = useState(null);
	const [formData, setformData] = useState(defaultFormValue());
	const [loading, setLoading] = useState(false);
	const [loadingSpinner, setLoadingSpinner] = useState("");

	useEffect(() => {
		let isMounted = true;
      	(async () => {
			if(searchParams.get("token") !== null){
				if(isMounted) {
					setToken(searchParams.get("token"))
				}
			} else {
				// Error no token
			}
		})();
		return () => { isMounted = false };
	}, [])

	const onClickRevealPass = () => {
		setShowPass(!showPass)
	}

	const onChange = (e: any, fieldName: string) => {
		setformData({ ...formData, [fieldName]: e.nativeEvent.target.value });
	}

	const onSubmit = async () => {
		if ((isEmpty(formData.email)) || (isEmpty(formData.password)) ) {
			ToastNotify.error(t('login.error-fields-mandatory'));
		} else if (size(formData.password) < 6 ) {
			ToastNotify.error(t('login.error-password-minimum'));
		} else {
			setLoading(true);
			try {
				   const response = await UserApi.recoverPassword(token, formData.email, formData.password);

					ToastNotify.success(t('remote.'+response.code));
			} catch(error) {
				ToastNotify.error(t('remote.'+error));
				setLoading(false);
				return;
			}
			setLoading(false);
			setformData(defaultFormValue());
		}
	}

	return (
		<CContainer sm className="loginContainer">
			<div className="loginWrapper">
				<h1 className="text-center mb-5 loginHeader">
					{t('remote.'+messageCode)}
				</h1>
				<CForm className="loginForm mt-2">
					<div className="mb-3">
						<CFormLabel htmlFor="inputEmail">{t('login.email')}</CFormLabel>
						<CFormInput onChange={(e) => onChange(e, "email")} type="email" id="inputEmail" />
					</div>
					<div className="mb-2 d-flex align-items-center">
						<div className="flex-grow-1">
							<CFormLabel htmlFor="inputPassword">{t('login.password')}</CFormLabel>
							<CFormInput onChange={(e) => onChange(e, "password")} type={showPass ? "text" : "password"} id="inputPassword" />
						</div>
						<div>
							{showPass
							? <CImage id="imgRevealPass" src={hidePassImg} 
									onClick={() => onClickRevealPass()} className="isLink" />
							: <CImage id="imgRevealPass" src={revealPassImg} 
									onClick={() => onClickRevealPass()} className="isLink" />}
						</div>
					</div>
					<CRow className="mb-4">
						<ToastContainer />
					</CRow>
					<CButton onClick={() => onSubmit()} className="mb-3 submitLogin">
						{t('register.submit')} &nbsp; {loadingSpinner}
					</CButton>

					<hr />
					<div className="text-center mt-1">
						<NavLink to="/login" className="registerLink">{t('register.login-here')}</NavLink>
					</div>
				</CForm>
				
			</div>
		</CContainer>
	);
}

export default RecovePassword;
