import { CNavItem } from '@coreui/react'

const _nav = { 
  adminItems: [
    {
      component: CNavItem,
      name: 'sidebar.dashboard',
      to: '/dashboard',
      badge: null,
    },
    {
      component: CNavItem,
      name: 'sidebar.leads',
      to: '/adminleads',
      badge: null,
    },
    {
      component: CNavItem,
      name: 'sidebar.clients',
      to: '/clients',
      badge: null,
    },
    {
      component: CNavItem,
      name: 'sidebar.report',
      to: '/report',
      badge: null,
    },
    {
      component: CNavItem,
      name: 'sidebar.admin',
      to: '/admin',
      badge: null,
    },
    {
      component: CNavItem,
      name: 'sidebar.statistics',
      to: '/admin-statistics',
      badge: null,
    }
  ],
  userItems: [
    {
      component: CNavItem,
      name: 'sidebar.dashboard',
      to: '/dashboard',
      badge: null,
    },
     {
       component: CNavItem,
        name: 'sidebar.panel',
        to: '/panel',
        badge: null,
      },
      {
        component: CNavItem,
        name: 'sidebar.my-leads',
        to: '/my-leads',
        badge: null,
      },
      {
        component: CNavItem,
        name: 'sidebar.my-account',
        to: '/my-account',
        badge: null,
      },
      {
        component: CNavItem,
        name: 'sidebar.statistics',
        to: '/statistics',
        badge: null,
      }
  ]
}

export default _nav
