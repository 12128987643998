import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppContent, AppSidebar, AppHeader } from './index';
import { ToastContainer } from 'react-toastify';
import { ToastNotify } from '../components/ToastNotify';
import { useSelector, useDispatch } from 'react-redux';
import {CButton,
  CCol,
  CModal,
  CModalBody,
  CRow,
  CModalHeader,
  CModalTitle} from '@coreui/react'
import { PlanApi } from '../api/PlanApi';
import { SessionApi } from '../api/SessionApi';
import { logoutCall, meCall } from '../api/endpoints';

export function DefaultLayout() {

  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.auth.user);
  const showError = useSelector((state: any) => state.changeState.showError);
  const showRemoteMessage = useSelector((state: any) => state.changeState.showRemoteMessage);
  const showMessage = useSelector((state: any) => state.changeState.showMessage);
  const errorMessage = useSelector((state: any) => state.changeState.errorMessage);
  const remoteMessage = useSelector((state: any) => state.changeState.remoteMessage);
  const infoMessage = useSelector((state: any) => state.changeState.infoMessage);
  const showPlans = useSelector((state: any) => state.changeState.showPlans);
  const showProducts = useSelector((state: any) => state.changeState.showProducts);
  const showChangePlans = useSelector((state: any) => state.changeState.showChangePlans);
  const showCancelPlan = useSelector((state: any) => state.changeState.showCancelPlan);
  const showRemoveUser = useSelector((state: any) => state.changeState.showRemoveUser);
  
  const { t } = useTranslation()
  const navigate = useNavigate();

  const [ plans, setPlans ] = useState([]);
  const [ products, setProducts ] = useState([]);
  const [ showModalPlans, setShowModalPlans ] = useState(false);
  const [ showModalProducts, setShowModalProducts ] = useState(false);
  const [ showModalChangePlans, setShowModalChangePlans ] = useState(false);
  const [ showModalCancelPlan, setShowModalCancelPlan ] = useState(false);
  const [ showModalRemoveUser, setShowModalRemoveUser ] = useState(false);
  const [ showModalBilling, setShowModalBilling ] = useState(false);
  const [ buttonDisabled, setButtonDisabled ] = useState(false);

  useEffect(()=> {
    if(showError) {
      if(errorMessage === 'not logged') {
        (async () => {
          //Close session for statistics
          const sessionId = localStorage.getItem('sessionID');
          await SessionApi.closeSession(sessionId, new Date());
          localStorage.removeItem('sessionID');
          localStorage.removeItem('sessionTSTP');
          //Dispatch error message
          dispatch({ type: 'set', errorMessage: '' });
          dispatch({ type: 'set', showError: false });
          //Redirect to login
          navigate("/login");
        })()
      } else {
        ToastNotify.error(t('remote.'+errorMessage));
        dispatch({ type: 'set', showError: false });
      }
      
    } else if (!showError && showMessage) {
      ToastNotify.success(infoMessage);
      dispatch({ type: 'set', showMessage: false });
    } else {
      dispatch({ type: 'set', errorMessage: '' });
      dispatch({ type: 'set', infoMessage: '' });
    }
  }, [showError, showMessage])

  useEffect(()=> {
    if(showRemoteMessage) {
        ToastNotify.success(t('remote.' + remoteMessage));
        dispatch({ type: 'set', showRemoteMessage: false });
    }
  }, [showRemoteMessage])

  useEffect(()=>{
    if( showPlans ) {
      let isMounted = true;
      (async () => {
        try {
          if( user && user.company && user.company.cif ) {
            const plansFromDb = await PlanApi.getAllPlans();
            if( isMounted ) {
              setPlans(plansFromDb);
              setShowModalPlans(true)
              dispatch({ type: 'plans', showPlans: false });
            }
          } else {
            dispatch({ type: 'plans', showPlans: false });
            setShowModalBilling(true)
          }
        } catch (error) {
          dispatch({ type: 'set', errorMessage: error });
          dispatch({ type: 'set', showError: true });
        }
      })()
      return () => { isMounted = false };
    }
  }, [showPlans])

  useEffect(()=>{
    if( showProducts ) {
      let isMounted = true;
      (async () => {
        try {
          if( user && user.company && user.company.cif ) {
            const productsFromDb = await PlanApi.getAllProducts();
            if( isMounted ) {
              setProducts(productsFromDb);
              setShowModalProducts(true)
              dispatch({ type: 'products', showProducts: false });
            }
          } else {
            dispatch({ type: 'plans', showPlans: false });
            setShowModalBilling(true)
          }
        } catch (error) {
          dispatch({ type: 'set', errorMessage: error });
          dispatch({ type: 'set', showError: true });
        }
      })()
      return () => { isMounted = false };
    }
  }, [showProducts])

  useEffect(()=>{
    let isMounted = true;
    if( showChangePlans ) {
      
      (async () => {
        try {
          const plansFromDb = await PlanApi.getAllPlans();
          if( isMounted ) {
            setPlans(plansFromDb);
            setShowModalChangePlans(true)
            dispatch({ type: 'plans', showChangePlans: false });
          }
        } catch (error) {
          dispatch({ type: 'set', errorMessage: error });
          dispatch({ type: 'set', showError: true });
        }
      })()
      return () => { isMounted = false };
    }
  }, [showChangePlans])

  useEffect(()=>{
    let isMounted = true;
    if( showRemoveUser ) {
      
      (async () => {
        try {
          if( isMounted ) {
            setShowModalRemoveUser(true)
            dispatch({ type: 'plans', showRemoveUser: false });
          }
        } catch (error) {
          dispatch({ type: 'set', errorMessage: error });
          dispatch({ type: 'set', showError: true });
        }
      })()
      return () => { isMounted = false };
    }
  }, [showRemoveUser])

  useEffect(()=>{
    let isMounted = true;
    if( showCancelPlan ) {
      
      (async () => {
        try {
          if( isMounted ) {
            setShowModalCancelPlan(true)
            dispatch({ type: 'plans', showCancelPlan: false });
          }
        } catch (error) {
          dispatch({ type: 'set', errorMessage: error });
          dispatch({ type: 'set', showError: true });
        }
      })()
      return () => { isMounted = false };
    }
  }, [showCancelPlan])

  const buyPlan = async(plan) => {
    setButtonDisabled(true)
    try {
      const stripeResponse = await PlanApi.pay(plan.id);
      localStorage.setItem('stripeResponse', 'Y');
      window.location.href = stripeResponse.url;
    } catch (error) {
      dispatch({ type: 'set', errorMessage: error });
      dispatch({ type: 'set', showError: true });
    }
    
    setShowModalPlans(false)
    setButtonDisabled(false)
  }

  const buyProduct = async(product) => {
    setButtonDisabled(true)
    try {
      const stripeResponse = await PlanApi.payProduct(product.id);
      
      window.location.href = stripeResponse.url;
    } catch (error) {
      dispatch({ type: 'set', errorMessage: error });
      dispatch({ type: 'set', showError: true });
    }
    
    setShowModalProducts(false)
    setButtonDisabled(false)
  }

  const changePlan = async(plan) => {
    setButtonDisabled(true)
    try {
      await PlanApi.changePlan(plan.id);
      await meCall();
    } catch (error) {
      dispatch({ type: 'set', errorMessage: error });
      dispatch({ type: 'set', showError: true });
    }
    
    setShowModalChangePlans(false)
    setButtonDisabled(false)
  }

  const cancelPlan = async() => {
    setButtonDisabled(true)
    try {
      await PlanApi.cancelPlan();
      await meCall();
    } catch (error) {
      dispatch({ type: 'set', errorMessage: error });
      dispatch({ type: 'set', showError: true });
    }
    
    setShowModalCancelPlan(false)
    setButtonDisabled(false)
  }

  const removeUser = async() => {
    setButtonDisabled(true)
    try {
      const response = await PlanApi.removeUser();
      
      dispatch({ type: 'set', remoteMessage: response.code });
      dispatch({ type: 'set', showRemoteMessage: true });

      const sessionId = localStorage.getItem('sessionID');

      if(sessionId) {
        await SessionApi.closeSession(sessionId, new Date());
        localStorage.removeItem('sessionID');
        localStorage.removeItem('sessionTSTP');
      }
      
      await logoutCall();
    } catch (error) {
      dispatch({ type: 'set', errorMessage: error });
      dispatch({ type: 'set', showError: true });
    }
    
    setShowModalRemoveUser(false)
    setButtonDisabled(false)
  }

  const goToMyAccount = () => {
    setShowModalBilling(false)
    navigate('/my-account')
  }

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-body">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <AppContent />
          <ToastContainer />
          <CModal alignment="center" size="lg" visible={showModalPlans} onClose={() => setShowModalPlans(false)}>
            <CModalHeader>
              <CModalTitle>{t('my-account.select-plan')}</CModalTitle>
            </CModalHeader>
            <CModalBody className="text-center ps-4 pe-4 pt-4 pb-4">
              <CRow className="justify-content-center">
                {
                  plans ?
                    plans.map((plan, index) => (
                      <CCol key={index} xs={12} md={5} lg={3} className="buyPlanBorder ms-2 me-2 mb-2">
                        <div>
                          <h5>{t('my-account.plan')}<br />{plan.name}</h5>
                          <p className="subtitle">{t('my-account.plan-leads-time', { number: plan.leadsLimit, duration: plan.stripeInterval })}</p>
                          <h2 className="align-bottom">{t('common.money-format', { qtty: plan.stripeAmount})}</h2>
                          <CButton className="btnBuyPlan" onClick={() => buyPlan(plan)} disabled={buttonDisabled}>{t('my-account.buy')}</CButton>
                        </div>
                        
                      </CCol>
                    ))
                  : 
                  ""
                }
              </CRow>
            </CModalBody>
          </CModal>

          <CModal alignment="center" size="lg" visible={showModalProducts} onClose={() => setShowModalProducts(false)}>
            <CModalHeader>
              <CModalTitle>{t('my-account.select-product')}</CModalTitle>
            </CModalHeader>
            <CModalBody className="text-center ps-4 pe-4 pt-4 pb-4">
              <CRow className="justify-content-center">
                {
                  products ?
                  products.map((product, index) => (
                      <CCol key={index} xs={12} md={5} lg={3} className="buyPlanBorder ms-2 me-2 mb-2">
                        <div>
                          <h5>{product.name}</h5>
                          <p className="subtitle">{product.leadsLimit} {t('my-account.leads')}</p>
                          <h2 className="align-bottom">{t('common.money-format', { qtty: product.stripeAmount})}</h2>
                          <CButton className="btnBuyPlan" onClick={() => buyProduct(product)} disabled={buttonDisabled}>{t('my-account.buy')}</CButton>
                        </div>
                      </CCol>
                    ))
                  : 
                  ""
                }
              </CRow>
            </CModalBody>
          </CModal>

          <CModal alignment="center" size="lg" visible={showModalChangePlans} onClose={() => setShowModalChangePlans(false)}>
            <CModalHeader>
              <CModalTitle>{t('my-account.change-new-plan')}</CModalTitle>
            </CModalHeader>
            <CModalBody className="text-center ps-4 pe-4 pt-4 pb-4">
              <CRow>
                {
                  plans ?
                    plans.map((plan, index) => (
                      <CCol key={index} xs={12} md={5} lg={3} className="buyPlanBorder ms-2 me-2 mb-2">
                        <div>
                          <h5>{t('my-account.plan')}<br />{plan.name}</h5>
                          <p>{t('my-account.plan-leads-time', { number: plan.leadsLimit, duration: plan.stripeInterval })}</p>
                          <h2 className="align-bottom">{t('common.money-format', { qtty: plan.stripeAmount})}</h2>
                          <CButton className="btnBuyPlan" onClick={() => changePlan(plan)} disabled={buttonDisabled}>{t('my-account.buy-new-plan')}</CButton>
                        </div>
                        
                      </CCol>
                    ))
                  : 
                  ""
                }
              </CRow>
            </CModalBody>
          </CModal>

          <CModal alignment="center" visible={showModalCancelPlan} onClose={() => setShowModalCancelPlan(false)}>
            <CModalHeader>
              <CModalTitle>{t('my-account.remove-confirmation-title')}</CModalTitle>
            </CModalHeader>
            <CModalBody className="text-center">
              <CRow>
                  <p>{t('my-account.remove-confirmation')}</p>
                  <div className="mt-3">
                    <CButton className="ms-2" onClick={() => cancelPlan()} disabled={buttonDisabled}>{'Confirmar'}</CButton>
                    <CButton className="ms-2" onClick={() => setShowModalCancelPlan(false)} color="secondary" >{'Volver'}</CButton>
                  </div>
              </CRow>
            </CModalBody>
          </CModal>

          <CModal alignment="center" visible={showModalRemoveUser} onClose={() => setShowModalRemoveUser(false)}>
            <CModalHeader>
              <CModalTitle>{t('my-account.remove-user-confirmation-title')}</CModalTitle>
            </CModalHeader>
            <CModalBody className="text-center">
              <CRow>
                  <p>{t('my-account.remove-user-confirmation')}</p>
                  <div className="mt-3">
                    <CButton className="ms-2" onClick={() => removeUser()} disabled={buttonDisabled}>{'Confirmar'}</CButton>
                    <CButton className="ms-2" onClick={() => setShowModalRemoveUser(false)} color="secondary" >{'Volver'}</CButton>
                  </div>
              </CRow>
            </CModalBody>
          </CModal>

          <CModal alignment="center" visible={showModalBilling} onClose={() => setShowModalBilling(false)}>
            <CModalBody className="text-center">
                <h2>{t('lead-card.warning')}</h2>
                <h5 className="mb-3">{t('my-account.fulfill-enterprise-info')}</h5>
                <div className="mt-3">
                    <CButton onClick={() => goToMyAccount()}>{t('my-account.go-to-myaccount')}</CButton>
                    <CButton onClick={() => setShowModalBilling(false)} color="secondary" className="ms-2">{t('lead-card.cancel')}</CButton>
                </div>
            </CModalBody>
          </CModal>

        </div>
      </div>
    </div>
  )
}