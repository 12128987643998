import React, { useEffect, useState } from 'react'
import {
    CButton,
    CCol,
    CFormSelect,
    CImage,
    CRow,
  } from '@coreui/react'
  import { useTranslation } from 'react-i18next';
import { LeadsApi } from '../api/LeadsApi';
import { MarcasApi } from '../api/MarcasApi';
import { DateUtils } from '../utils/DateUtils';

import borrarImg from '../assets/images/borrar.png'

const EMPTY_LABEL = ''
const DEFAULT_SELECT_VALUE = '-1'

export const initialFilterValues = {
    brand : {label: EMPTY_LABEL, value: DEFAULT_SELECT_VALUE},
    model : {label: EMPTY_LABEL, value: DEFAULT_SELECT_VALUE},
    segment : {label: EMPTY_LABEL, value: DEFAULT_SELECT_VALUE},
    province : {label: EMPTY_LABEL, value: DEFAULT_SELECT_VALUE},
    date : {label: EMPTY_LABEL, value: DEFAULT_SELECT_VALUE},
    lastAction : {label: EMPTY_LABEL, value: DEFAULT_SELECT_VALUE},
    favorite : false,
    all: false
}

const DataTableFiltersMyLeads = ({ handleChange, handleReset }) => {

    const { t } = useTranslation()
    const BRAND_PLACEHOLDER_TXT = t('datatable.brand')
    const MODEL_PLACEHOLDER_TXT = t('datatable.model')
    const SEGMENT_PLACEHOLDER_TXT = t('datatable.segment')
    const ACTION_PLACEHOLDER_TXT = t('datatable.action')
    const PROVINCES_PLACEHOLDER_TXT = t('datatable.province')
    
  //Initialize filterValues in state
  const [filterValues, setFilterValues] = useState(initialFilterValues);

  //Initialize Model options in state, because depends on Brand selection
  const [ optionsBrand, setOptionsBrand ] = useState([]);
  const [ optionsProvince, setOptionsProvince ] = useState([{ label: PROVINCES_PLACEHOLDER_TXT, value: DEFAULT_SELECT_VALUE }]);
  const [ optionsModel, setOptionsModel ] = useState([{ label: MODEL_PLACEHOLDER_TXT, value: DEFAULT_SELECT_VALUE }])
  const [ optionsSegment, setOptionsSegment ] = useState([{ label: SEGMENT_PLACEHOLDER_TXT, value: DEFAULT_SELECT_VALUE }])
  const [ optionsAction, setOptionsAction ] = useState([{ label: MODEL_PLACEHOLDER_TXT, value: DEFAULT_SELECT_VALUE }])
  const [apiCall, setAPiCall ] = useState(false);
  const [ modelFilterDisabled, setModelFilterDisabled ] = useState(true)
  const [ checkedFavorites, setCheckedFavorites ] = useState(false);
  const [ checkedAll, setCheckedAll ] = useState(false);

  useEffect(() =>{
      let isMounted = true;
      (async () => {
        const apiFilters = await MarcasApi.getMyFilters({
            brand: filterValues.brand.value,
            model: filterValues.model.value,
            segment: filterValues.segment.value,
            province: filterValues.province.value
          });
          const allBrands = apiFilters.brand;
          allBrands.unshift({label: BRAND_PLACEHOLDER_TXT, value: DEFAULT_SELECT_VALUE})
          const allSegments = apiFilters.segment;
          allSegments.unshift({label: SEGMENT_PLACEHOLDER_TXT, value: DEFAULT_SELECT_VALUE})
          const allProvinces = apiFilters.province;
          allProvinces.unshift({label: PROVINCES_PLACEHOLDER_TXT, value: DEFAULT_SELECT_VALUE})

          
          const allActions = await LeadsApi.getFilterActions(filterValues.all)
          
          allActions.map((item)=> ( item.label = item.label ))
          allActions.unshift({label: ACTION_PLACEHOLDER_TXT, value: DEFAULT_SELECT_VALUE})
          if(isMounted) {
              setOptionsBrand(allBrands);
              setOptionsSegment(allSegments);
              setOptionsProvince(allProvinces);
              setOptionsAction(allActions)
          }
      })()
      return () => { isMounted = false };
  }, [])



  useEffect(() => { 
    let isMounted = true;
    (async () => {
      if(apiCall) return;
      if(!isMounted) return;
      setAPiCall(true);
      
      const apiFilters = await MarcasApi.getMyFilters({
        brand: filterValues.brand.value,
        model: filterValues.model.value,
        segment: filterValues.segment.value,
        province: filterValues.province.value
      });
      if( filterValues.brand.value == DEFAULT_SELECT_VALUE) {
        setOptionsModel([{ label: MODEL_PLACEHOLDER_TXT, value: DEFAULT_SELECT_VALUE }]);
        setFilterValues(prevState => ({
            ...prevState,
            model: {
                label: EMPTY_LABEL,
                value: DEFAULT_SELECT_VALUE
            },
        }))
        setModelFilterDisabled(true);
      } else {
        setModelFilterDisabled(false)
        setOptionsModel([...apiFilters.model]);
      }

        const allBrands = apiFilters.brand;
        allBrands.unshift({label: BRAND_PLACEHOLDER_TXT, value: DEFAULT_SELECT_VALUE})
        const allSegments = apiFilters.segment;
        allSegments.unshift({label: SEGMENT_PLACEHOLDER_TXT, value: DEFAULT_SELECT_VALUE})
        const allProvinces = apiFilters.province;
        allProvinces.unshift({label: PROVINCES_PLACEHOLDER_TXT, value: DEFAULT_SELECT_VALUE})
        const allActions = await LeadsApi.getFilterActions(filterValues.all)
          
          allActions.map((item)=> ( item.label = item.label ))
          allActions.unshift({label: ACTION_PLACEHOLDER_TXT, value: DEFAULT_SELECT_VALUE})
        
        setOptionsBrand(allBrands);
        setOptionsSegment(allSegments);
        setOptionsProvince(allProvinces);
        setOptionsAction(allActions);
          

      //On each UI rendering, send filterValues to parent component
      setAPiCall(false);
      
    })()
    handleChange(filterValues);
    return () => { isMounted = false };
  },[filterValues])

  //OPTIONS
    const getYesterday = () => {
        return new Date(new Date().getTime() - 24*60*60*1000);
    }
    const getLast7Days = () => {
        return new Date(new Date().getTime() - (24*60*60*1000*7));
    }
    const today = DateUtils.formatLocaleDate(new Date(), t('common.date-locale'))
    const yesterday = DateUtils.formatLocaleDate(getYesterday(), t('common.date-locale'))
    const last7days = DateUtils.formatLocaleDate(getLast7Days(), t('common.date-locale'))
    const optionsDate = [
        { label: t('panel.register-date'), value: '0' },
        { label: t('panel.today'), value: today },
        { label: t('panel.yesterday'), value: yesterday },
        { label: t('panel.last7days'), value: last7days },
        { label: t('panel.all'), value: '0' }
    ]


  //UI methods
  const checkboxFavoritos = (e) => {
    setCheckedFavorites(!checkedFavorites);
    setFilterValues((prevState) => ({...prevState, [e.target.name]: !checkedFavorites}));
  }

  const checkboxAll = (e) => {
      setCheckedAll(!checkedAll);
      setFilterValues((prevState) => ({...prevState, [e.target.name]: !checkedAll}));
  }
  const resetForm = () => {
      setFilterValues((prevState) => ({...prevState, ...initialFilterValues}))
      handleReset()
  }

  return (
      <CRow className="g-3 mb-3 mt-3">
          <CCol xs="auto">
              <CFormSelect name="date" size="sm" options={optionsDate} value={filterValues.date.value}
                  onChange={e => setFilterValues({...filterValues, [e.target.name]: { label: e.target.options[e.target.selectedIndex].text, value : e.target.options[e.target.selectedIndex].value}})} />
          </CCol>
          <CCol xs="auto">
              <CFormSelect name="brand" size="sm" options={optionsBrand} value={filterValues.brand.value}
              onChange={e => setFilterValues({...filterValues, [e.target.name]: { label: e.target.options[e.target.selectedIndex].text, value : e.target.options[e.target.selectedIndex].value}})} />
          </CCol>
          <CCol xs="auto">
              <CFormSelect name="model" size="sm" className="filterField" value={filterValues.model.value} disabled={modelFilterDisabled}
              options={optionsModel} onChange={e => setFilterValues({...filterValues, [e.target.name]: { label: e.target.options[e.target.selectedIndex].text, value : e.target.options[e.target.selectedIndex].value}})} />
          </CCol>
          <CCol xs="auto">
              <CFormSelect name="segment" size="sm" className="filterField" value={filterValues.segment.value}
               options={optionsSegment} onChange={e => setFilterValues({...filterValues, [e.target.name]: { label: e.target.options[e.target.selectedIndex].text, value : e.target.options[e.target.selectedIndex].value}})} />
          </CCol>
          <CCol xs="auto">
              <CFormSelect name="province" size="sm" options={optionsProvince} value={filterValues.province.value}
                  onChange={e => setFilterValues({...filterValues, [e.target.name]: { label: e.target.options[e.target.selectedIndex].text, value : e.target.options[e.target.selectedIndex].value}})} />
          </CCol>
          <CCol xs="auto">
              <CFormSelect name="lastAction" size="sm" options={optionsAction} value={filterValues.lastAction.value}
                  onChange={e => setFilterValues({...filterValues, [e.target.name]: { label: e.target.options[e.target.selectedIndex].text, value : e.target.options[e.target.selectedIndex].value}})} />
          </CCol>
          <CCol xs="auto">
              <CButton name="favorite" id="btnFavorites" className={filterValues.favorite ? "active" : ""} 
                  onClick={(e) => checkboxFavoritos(e)}>
                  {t('panel.favorites')}
              </CButton>
          </CCol>
          <CCol xs="auto">
              <CButton name="all" id="btnAll" className={filterValues.all ? "active" : ""} 
                  onClick={(e) => checkboxAll(e)}>
                  {t('panel.view-all')}
              </CButton>
          </CCol>
          <CCol xs="auto" className='text-end'>
              <CButton type="reset" className="btnDeleteFilters" onClick={resetForm} >
                <CImage src={borrarImg} />
              </CButton>
          </CCol>
      </CRow>
  )
}

export default DataTableFiltersMyLeads