import { combineReducers } from "redux";
import { authenticationReducer } from "./auth.reducer";
import changeState from "./state.reducer";
import leadsReducer from "./leads.reducer";
import {planReducer} from "./plan.reducer";

export default combineReducers({ 
	auth: authenticationReducer,
	planReducer,
	changeState,
	leadsReducer 
});