import { LOGOUT_REQUEST } from "../contexts/actions/userActions";
import store from "../contexts/store";
import { authApiCall } from "./apiCalls";
import { meCall } from "./endpoints";

const getAll = (): Promise<any> => {
	return new Promise((resolve, reject) => {
		authApiCall.get("/marca/all")
			.then(({ data, status }) => {
				resolve(data);
			})
			.catch((error) => { reject(processError(error)) })
	});
}

const getAllWithLeads = (): Promise<any> => {
	return new Promise((resolve, reject) => {
		authApiCall.get("/marca/haveLeads")
			.then(({ data, status }) => {
				resolve(data);
			})
			.catch((error) => { reject(processError(error)) })
	});
}

const getFilters = (params): Promise<any> => {
	return new Promise((resolve, reject) => {
		authApiCall.get("/marca/getFilters", {params})
			.then(({ data, status }) => {
				resolve(data);
			})
			.catch((error) => { reject(processError(error)) })
	});
}

const getMyFilters = (params): Promise<any> => {
	return new Promise((resolve, reject) => {
		authApiCall.get("/marca/getMyFilters", {params})
			.then(({ data, status }) => {
				resolve(data);
			})
			.catch((error) => { reject(processError(error)) })
	});
}

const addMarca = (marcaId: string): Promise<any> => {
	return new Promise((resolve, reject) => {
        const params = {marcaId};
		authApiCall.post("/marca/company", params)
			.then(({ data, status }) => {
				resolve(data);
                meCall();
			})
			.catch((error) => { reject(processError(error)) })
	});
}

const removeMarca = (marcaId: string): Promise<any> => {
	return new Promise((resolve, reject) => {
        const data = {id: marcaId};
		authApiCall.delete("/marca/company", {data})
			.then(({ data, status }) => {
				resolve(data);
                meCall();
			})
			.catch((error) => { reject(processError(error)); })
	});
}

const processError = (error: any) => {
    if (error.response) {
        const serverError = error.response.data;
        if(serverError.statusCode === 403) {
            store.dispatch({type: LOGOUT_REQUEST.type});
            return 'error.0003';
        }
		else if(serverError.statusCode === 500) {
            return 'error.0001';
        }
		else if(serverError.statusCode === 400) {
            return 'error.'+serverError.message;
        }
        return serverError.message;
    } else if (error.request) {
        return 'error.0002';
    } else {
        return 'error.0001';
    }
}


export const MarcasApi = {
    addMarca,
    removeMarca,
    getAll,
    getAllWithLeads,
    getMyFilters,
    getFilters
  };