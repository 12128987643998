import React, { Suspense, useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { connect, useDispatch } from "react-redux";
import i18n from './i18n';
import { meCall } from "./api/endpoints";

import Login from "./pages/Login";
import Register from "./pages/Register";
import Success from "./pages/Success";
import Cancel from "./pages/Cancel";
import { DefaultLayout } from "./layouts/DefaultLayout";

import './assets/styles/layouts.css'
import './assets/styles/pages.css';
import './assets/animations/loading-animation.css';
import Confirmation from "./pages/Confirmation";
import Remove from "./pages/Remove";
import RecovePassword from "./pages/RecovePassword";
import RememberPassword from "./pages/RememberPassword";
import ConfirmEmail from "./pages/ConfirmEmail";
import SendConfirmation from "./pages/SendConfirmation";

const loading: any = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// ********  REDUX UTILS ********

// Puede acceder al estado global
const mapStateToProps = state => {
  return {
    user: state.auth.user,
    loggedIn: state.auth.loggedIn,
    logginIn: state.auth.logginIn
  }
}

// ********  END OF REDUX UTILS ********

const App = (props) => {

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const stripeResponse = localStorage.getItem('stripeResponse');
  
  const [locale, setLocale] = useState(i18n.language);
  i18n.on('languageChanged', (lng) => setLocale(i18n.language));

    useEffect(() => {
      (async () => {
        if(stripeResponse==='Y') {
          if(location.pathname.includes("cancel")) {
            await meCall();
            navigate("/cancel")
            return;
          } else if(location.pathname.includes("success")) {
            await meCall();
            navigate("/success")
            return;
          } 
          localStorage.removeItem('stripeResponse');
        }
      })()
    }, [location])

    // Escuchamos al cambio en logginIn
    useEffect(() => {
      (async () => {
        try {
          if(
            !location.pathname.includes("confirmation") && 
            !location.pathname.includes("success") && 
            !location.pathname.includes("confirmemail") && 
            !location.pathname.includes("remove") &&
            !location.pathname.includes("register") &&
            !location.pathname.includes("resend-confirmation") &&
            !location.pathname.includes("recoverPassword") &&
            !location.pathname.includes("rememberPassword")
          ){
            const sessionTstp: number = Number(localStorage.getItem('sessionTSTP'));
            dispatch({ type: 'session', lastRequestTstp: sessionTstp });
            //Check if user is logged in server, if not throws error and redirect to login
            await meCall();
           
          }
          if(location.pathname.includes("success")) {
            await meCall();
            navigate("/success")
            return;
          } 
        } catch (error) {
          if(
            !location.pathname.includes("confirmation") && 
            !location.pathname.includes("confirmemail") && 
            !location.pathname.includes("remove") &&
            !location.pathname.includes("register") &&
            !location.pathname.includes("recoverPassword") &&
            !location.pathname.includes("resend-confirmation") &&
            !location.pathname.includes("success") &&
            !location.pathname.includes("rememberPassword")
          ) {
            navigate("/login");
          }
          
        }
      })()
    }, [])

    return (
      <>
        <Suspense fallback={loading}>
          <Routes>
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/confirmation" element={<Confirmation />} />
            <Route path="/resend-confirmation" element={<SendConfirmation />} />
            <Route path="/confirmemail" element={<ConfirmEmail />} />
            <Route path="/recoverPassword" element={<RecovePassword />} />
            <Route path="/rememberPassword" element={<RememberPassword />} />
            <Route path="/remove" element={<Remove />} />
            <Route path="/success" element={<Success />} />
            <Route path="/cancel" element={<Cancel />} />
            
            
            <Route path="*" element={ <DefaultLayout /> } />
          </Routes>
        </Suspense>
      </>
    );
}


/** REDUX EXPORT */

const ConnectedAppComponent = connect(
  mapStateToProps
)(App)

export default ConnectedAppComponent;
function useHistory() {
  throw new Error("Function not implemented.");
}

