import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { LeadsApi } from '../api/LeadsApi'
import AppDataTable from '../components/AppDataTable'
import { CImage } from '@coreui/react'

import imgContacts from '../assets/images/circle-contacts.png'
import { meCall } from '../api/endpoints'

const Panel = () => {

  const { t } = useTranslation()
  const dispatch = useDispatch();

  const [ leadsList, setLeadsList ] = useState([]);
  const [ loading, setLoading ] = useState(true);
  const [ reloadLeads, setReloadLeads ] = useState(true)

  useEffect(() => {
    if(reloadLeads) {
      (async () => {
        try {
          const data = await LeadsApi.getAllLeads()
          setLeadsList(data);
          setReloadLeads(false)
          setLoading(false);
        } catch (error) {
          dispatch({ type: 'set', errorMessage: error });
          dispatch({ type: 'set', showError: true });
        }
      })()
    }
  }, [reloadLeads])

  useEffect(() => {
    meCall();
  }, [])

  return(
    <>
      <h1>{t('panel.panel')}</h1>
      
      <AppDataTable setData={setLeadsList} data={leadsList} loading={loading} setReload={setReloadLeads}/>
      
      <div className="mt-4 ms-5 contactsLegend">
        <h6><CImage src={imgContacts} className="align-text-top" /> {t('panel.ncontacts')}</h6>
      </div>
    </>
  )
}

export default Panel