import { CImage } from '@coreui/react'
import favoriteYes from '../assets/images/favorite-yes.png'
import favoriteNo from '../assets/images/favorite-no.png'

export function FavoriteCheckbox ({ id, checked, addFavorite, removeFavorite }) {
    
    
    const handleAddFavorite = () => {
        addFavorite(id)
    }

    const handleRemoveFavorite = () => {
        removeFavorite(id)
    }

    return (
        <>
            {checked
            ? <CImage src={favoriteYes} className="isLink" onClick={handleRemoveFavorite} />
            : <CImage src={favoriteNo} className="isLink" onClick={handleAddFavorite} />}
        </>
    )
}