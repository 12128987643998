import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux';
import { MarcasApi } from '../api/MarcasApi'
import makeAnimated from 'react-select/animated';
import { connect } from "react-redux";
import { DateUtils } from '../utils/DateUtils';
import { PlanApi } from '../api/PlanApi';
import { meCall, updateCustomer } from '../api/endpoints';
import { CAlert, CButton, 
  CCol, 
  CForm,
  CFormInput,
  CFormLabel,
  CImage,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner
 } from '@coreui/react'
import Select from 'react-select'

import accountImg from '../assets/images/account.png'
import leadsImg from '../assets/images/organigrama.png'
import calendarImg from '../assets/images/calendar.png'
import brandImg from '../assets/images/etiqueta.png'
import visaImg from '../assets/images/visa.png'
import mastercardImg from '../assets/images/mastercard.png'
import invoiceImg from '../assets/images/paper.png'
import addLeadsImg from '../assets/images/add-leads.png'

const MyAccount = (props) => {

  //Current plan can be empty, init state needed
  const initialPlan = {
    name: "",
    leadsLimit: "",
    caducity: "",
    card_num: "",
    card_exp: "",
    card_brand: "",
    card: false,
    nextPayment: "",
    amount:"",
    addicionalCharges: "",
    nextplan: false,
    nextLeadsLimit: 0,
    nextName: "",
    extraLeads: 0,
    cancel_at_period_end: false
  }

  const { t } = useTranslation()
  const dispatch = useDispatch();

  const user = useSelector((state: any) => state.auth.user);
  const warningBilling = useSelector((state: any) => state.changeState.warningBilling);

  //For Multi Select (react-select lib)
  const animatedComponents = makeAnimated();
  
  const [loadingData, setLoadingData] = useState(true);
  const [company, setCompany] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(initialPlan);
  const [brands, setBrands] = useState([]);
  const [invoices, setInvoices] = useState([])
  const [showModalEditCompany, setShowModalEditCompany] = useState(false)
  const [companyValues, setCompanyValues] = useState(null);
  const [disableMarcas, setDisableMarcas] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState("");
  const [showAlertBilling, setShowAlertBilling] = useState(false);

  //Listening for disableMarcas, for showing spinner while running async task of updating user brands
	useEffect(() => {
		disableMarcas
		? setLoadingSpinner(getSpinner)
		: setLoadingSpinner("");
	}, [disableMarcas])
	const getSpinner: any = ( <CSpinner component="span" size="sm" aria-hidden="true" color="secondary" /> )
  
  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const allBrands = await MarcasApi.getAll();
        const allInvoices = await PlanApi.getInvoices();
        allInvoices.sort(compare);
        
        if(isMounted) {
          setBrands(allBrands);
          setInvoices(allInvoices);
        }
      } catch (error) {
        dispatch({ type: 'set', errorMessage: error });
        dispatch({ type: 'set', showError: true });
      }
      
    })()
    return () => { isMounted = false };
  }, []);
  
  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        if ( user ) {
          const userCompany = user.company ? user.company : null;
          const plan = await PlanApi.getActualPlan();
          if( plan ) {
            plan.caducity = plan.current_period_end ? DateUtils.formatStripeDate(plan.current_period_end,t('common.date-locale')) : null
            plan.nextPayment = plan.current_period_end ? DateUtils.formatStripeDate(plan.current_period_end,t('common.date-locale')) : null
            plan.extraLeads = user.plan.extraLeads;
            if(plan.card) {
              plan.card_num = plan.card_numbers;
              plan.card_exp = plan.card_exp_month+"/"+plan.card_exp_year;
            }
          }
          if( userCompany ) {
            //For avoiding error null input values
            userCompany.email = userCompany.email ? userCompany.email : undefined;
            userCompany.street = userCompany.street ? userCompany.street : undefined;
            userCompany.cif = userCompany.cif ? userCompany.cif : undefined;
            userCompany.cp = userCompany.cp ? userCompany.cp : undefined;
            userCompany.city = userCompany.city ? userCompany.city : undefined;
            userCompany.country = userCompany.country ? userCompany.country : undefined;
            userCompany.phone = userCompany.phone ? userCompany.phone : undefined;
          }
          //Show or hide warning complete billing information
          warningBilling ? setShowAlertBilling(true) : setShowAlertBilling(false);

          if (isMounted) {
            setCompany(userCompany);
            setCompanyValues(userCompany)
            if( plan ) {
              setCurrentPlan(plan);
            }
          }
          setLoadingData(false)
        }
        setDisableMarcas(false);
      } catch (error) {
        dispatch({ type: 'set', errorMessage: error });
        dispatch({ type: 'set', showError: true });
      }
    })()
    return () => { isMounted = false };
  }, [user, warningBilling])

  function compare(a, b) {
    const dateA = a.createdAt;
    const dateB = b.createdAt;
  
    let comparison = 0;
    if (dateA < dateB) {
      comparison = 1;
    } else if (dateA > dateB) {
      comparison = -1;
    }
    return comparison;
  }

  const changePlan = () => {
    dispatch({ type: 'plans', showChangePlans: true });
  }

  const buyPlan = () => {
    //Show modal for select Plan, in DefaultLayoyt.tsx
    dispatch({ type: 'plans', showPlans: true });
  }

  const reactivatePlan = async() => {
    try {
      await PlanApi.reactivatePlan();
      await meCall();
    } catch (error) {
      dispatch({ type: 'set', errorMessage: error });
      dispatch({ type: 'set', showError: true });
    }
  }

  const cancelPlan = async () => {
    dispatch({ type: 'plans', showCancelPlan: true });
  }

  const removeUser = async () => {
    dispatch({ type: 'plans', showRemoveUser: true });
  }

  const updateBrands = async (selectedValues) => {
    setDisableMarcas(true);
    selectedValues.forEach(async selected => {
      if(user.marcas.find(marca => marca.value === selected.value)) {

      } else {
        await MarcasApi.addMarca(selected.value);
      }
    });
    user.marcas.forEach(async userMarca => {
      if(selectedValues.find(marca => marca.value === userMarca.value)) {

      } else {
        await MarcasApi.removeMarca(userMarca.value);
      }
    });
  }

  const editPaymentMethod = async () => {
    try {
      const stripeResponse = await PlanApi.modifyPayment();
      localStorage.setItem('stripeResponse', 'Y');
      window.location.href = stripeResponse.url;
    } catch (error) {
      dispatch({ type: 'set', errorMessage: error });
      dispatch({ type: 'set', showError: true });
    }
  }

  const editCompanyInfo = () => {
    setShowModalEditCompany(true)
  }

  const handleEditCompany = async (event) => {
    event.preventDefault()

    try {
      await updateCustomer(
        companyValues.name,
        companyValues.email,
        companyValues.street,
        companyValues.cif,
        companyValues.cp,
        companyValues.city ?? '',
        companyValues.country ?? '',
        companyValues.phone ?? ''
      );
      
      setShowModalEditCompany(false);
      dispatch({ type: 'billing', warningBilling: false });
      dispatch({ type: 'set', infoMessage: t('common.edited')});
      dispatch({ type: 'set', showMessage: true });

      setTimeout(async() => {
        await meCall();
      }, 1000);

    } catch(error) {
      dispatch({ type: 'set', errorMessage: error });
      dispatch({ type: 'set', showError: true });
      setCompanyValues(company)
      setShowModalEditCompany(false);
    }
  }

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <>
    {loadingData ?
        <div className="d-flex justify-content-center">
          <CSpinner component="span" aria-hidden="true" color="warning" />
        </div>
      :
    <>
    <h1>{t('my-account.header')}</h1>
    { showAlertBilling ?
        <CAlert color="danger"><b>{t('my-account.required-action')}:</b> {t('my-account.fulfill-enterprise-info')}</CAlert>
      : ''
    }
      <div>
      {warningBilling ?
        <CRow className="mb-5">
          <CCol>
            <h4 className="ms-3 mb-2">{t('my-account.enterprise-info')}</h4>
            <div className="accountBox">
              <CRow>
                {
                  company ?
                  <CCol lg={9} className="accountGroup">
                    <p>{company.name}</p>
                    { company.cif ?
                      <><p>{t('my-account.cif')}: {company.cif}</p>
                      <p>{company.street}</p>
                      <p>{company.cp} {company.city}</p>
                      <p>{company.country}</p>
                      <p>{company.email}</p></>
                    : <p className='info-edit-company'>{t('my-account.complete-company-info')}</p>
                    }
                  </CCol>
                  : <CCol lg={9} className="accountGroup">{t('my-account.no-company-info')}</CCol>
                }
                <CCol lg={3} className="text-end">
                  <CButton className="myAccountBtn" onClick={editCompanyInfo}>{t('my-account.edit-info')}</CButton>
                </CCol>
              </CRow>
            </div>
          </CCol>
        </CRow>
      : ""}
      
        
        <CRow>
          <CCol>
            <h4 className="ms-3 mb-3">{t('my-account.general')}</h4>
            <div className="accountBox">
              <CRow>
                <CCol lg={9}>
                  <CRow>
                    <CCol xl={6} lg={6} className="accountGroup d-flex">
                      <div>
                        <CImage src={accountImg} alt="Brand label"/>
                      </div>
                      <div className="ms-2">
                        <p className="label">{t('my-account.selected-pack')}</p>
                        <p><b>{currentPlan.name ? currentPlan.name : t('my-account.no-plan-selected')}</b></p>
                      </div>
                    </CCol>
                    <CCol xl={6} lg={6} className="accountGroup d-flex">
                      <div>
                        <CImage src={calendarImg} alt="Calendar"/>
                      </div>
                      { currentPlan.cancel_at_period_end ?
                        <div className="ms-2">
                          <p className="red"><b>{t('common.endAt')}</b></p>
                          <p className="red"><b>{currentPlan.caducity ? currentPlan.caducity : "-"}</b></p>
                        </div>
                        :
                        <div className="ms-2">
                          <p className="label">{t('my-account.expires')}</p>
                          <p><b>{currentPlan.caducity ? currentPlan.caducity : "-"}</b></p>
                        </div>
                      }
                    </CCol>
                  </CRow>
                  <CRow className='mt-3'>
                    <CCol xl={6} lg={6} className="accountGroup d-flex">
                      <div>
                        <CImage src={leadsImg} alt="Leads"/>
                      </div>
                      <div className="ms-2">
                        <p className="label">{t('my-account.hired-leads')}</p>
                        <p><b>{currentPlan.leadsLimit ? currentPlan.leadsLimit : "-"}</b></p>
                      </div>
                    </CCol>
                    {
                      currentPlan.extraLeads && currentPlan.extraLeads>0 ?
                        <CCol xl={6} lg={6} className="accountGroup d-flex">
                          <div>
                            <CImage src={addLeadsImg} alt="Leads"/>
                          </div>
                          <div className="ms-2">
                            <p className="label">{t('my-account.extra-leads')}</p>
                            <p><b>{currentPlan.extraLeads ? currentPlan.extraLeads : "-"}</b></p>
                          </div>
                        </CCol>
                      : ''
                    }
                  </CRow>
                  { user ?
                  <CRow className="mt-3">
                    <CCol lg={10} className="accountGroup">
                      <div className="d-flex">
                        <div>
                          <CImage src={brandImg} alt="Brand label"/>
                        </div>
                        <div className="ms-2 mb-3">
                          <p className="label">{t('my-account.brands')}</p>
                        </div>
                      </div>
                      <Select closeMenuOnSelect={false}
                          components={animatedComponents}
                          defaultValue={user.marcas}
                          isMulti
                          isClearable={false}
                          isDisabled={disableMarcas}
                          options={brands}
                          placeholder={t('my-account.select')}
                          onChange={(values) => updateBrands(values)} />
                    </CCol>
                    <CCol className='d-flex align-items-end pb-2'>{loadingSpinner}</CCol>
                  </CRow>
                  : <></>
                  }
                </CCol>
                <CCol lg={3} className="text-end">
                  {
                    currentPlan.name ?
                        currentPlan.cancel_at_period_end ?
                        <CButton className="myAccountBtn" onClick={reactivatePlan}>{t('my-account.reactivate-plan')}</CButton>
                        :
                        <CButton className="myAccountBtn" onClick={cancelPlan}>{t('my-account.remove-plan')}</CButton> 
                      :
                      <CButton className="myAccountBtn" onClick={buyPlan}>{t('my-account.buy-plan')}</CButton>
                      
                  }
                </CCol>
              </CRow>
            </div>
          </CCol>
        </CRow>
        {
          currentPlan.nextplan ?
            <CRow className="mt-5">
              <CCol>
                <h4 className="ms-3 mb-3">{t('my-account.next-plan')}</h4>
                    <div className="accountBox">
                      <CRow>
                        <CCol lg={4} className="accountGroup d-flex">
                        <div>
                          <CImage src={accountImg} alt="Brand label"/>
                        </div>
                        <div className="ms-2">
                          <p className="label">{t('my-account.selected-pack')}</p>
                          <p><b>{currentPlan.nextName}</b></p>
                        </div>
                      </CCol>
                      <CCol lg={4} className="accountGroup d-flex">
                        <div>
                          <CImage src={leadsImg} alt="Leads"/>
                        </div>
                        <div className="ms-2">
                          <p className="label">{t('my-account.hired-leads')}</p>
                          <p><b>{currentPlan.nextLeadsLimit}</b></p>
                        </div>
                      </CCol>
                      <CCol className="accountGroup d-flex mb-3">
                        <div>
                          <CImage src={calendarImg} alt="Calendar"/>
                        </div>
                        <div className="ms-2">
                          <p className="label">{t('my-account.starts')}</p>
                          <p><b>{currentPlan.caducity ? currentPlan.caducity : "-"}</b></p>
                        </div>
                      </CCol>
                      <CCol className="text-end">
                        <CButton className="myAccountBtn" onClick={cancelPlan}>{t('my-account.cancel-plan')}</CButton>
                      </CCol>
                      </CRow>
                    </div>
                </CCol>
            </CRow>
          : ""
        }
        <CRow className="mt-5">
          <CCol>
            <h4 className="ms-3 mb-3">{t('my-account.payment-info')}</h4>
            <div className="accountBox">
              {currentPlan && currentPlan.card 
              ? <>
                <CRow className="d-flex align-items-center">
                  <CCol lg={1}>
                    {currentPlan.card_brand.toUpperCase().includes("VISA") ?
                      <CImage src={visaImg} alt="Visa" />
                    : 
                      <CImage src={mastercardImg} alt="Mastercard" />
                    }
                  </CCol>
                  <CCol lg={6} className="accountGroup ps-3">
                    <p className="label">{t('my-account.card-number', {card: capitalize(currentPlan.card_brand), number : currentPlan.card_num})}</p>
                    <p className="label">{t('my-account.expires-date', {number : currentPlan.card_exp})}</p>
                  </CCol>
                  <CCol lg={5} className="text-end">
                    <CButton className="myAccountBtn" onClick={editPaymentMethod}>{t('my-account.edit-payment')}</CButton>
                  </CCol>
                </CRow>
                <CRow className='mt-3'>
                  <CCol lg={2} className="d-flex align-items-top">
                    <p><b>{t('my-account.next-payment')}: </b></p>
                  </CCol>
                  <CCol lg={2}>
                    <p><b>{currentPlan.nextPayment}</b></p>
                  </CCol>
                  <CCol lg={1}>
                    <p><b>{currentPlan.amount+t('common.money-symbol')}</b></p>
                  </CCol>
                  <CCol lg={3}>
                    <p><b>{currentPlan.addicionalCharges ? currentPlan.addicionalCharges : t('my-account.no-charges')}</b></p>
                  </CCol>
                </CRow>
                </>
              : <CRow>
                <CCol lg={7} className="accountGroup">{t('my-account.no-payment-info')}</CCol>
                <CCol lg={5} className="text-end">
                  <CButton className="myAccountBtn" onClick={editPaymentMethod}>{t('my-account.edit-payment')}</CButton>
                </CCol>
              </CRow>
            }
            </div>
          </CCol>
        </CRow>

        {!warningBilling ?
        <CRow className="mt-5">
        <CCol>
          <h4 className="ms-3 mb-3">{t('my-account.enterprise-info')}</h4>
          <div className="accountBox">
            <CRow>
              {
                company ?
                <CCol lg={9} className="accountGroup">
                  <p className="label">{company.name}</p>
                  { company.cif ?
                    <><p className="label">{t('my-account.cif')}: {company.cif}</p>
                    <p className="label">{company.street}</p>
                    <p className="label">{company.cp} {company.city}</p>
                    <p className="label">{company.country}</p>
                    <p className="label">{company.email}</p></>
                  : <p className='info-edit-company label'>{t('my-account.complete-company-info')}</p>
                  }
                </CCol>
                : <CCol lg={9} className="accountGroup">{t('my-account.no-company-info')}</CCol>
              }
              <CCol lg={3} className="text-end">
                <CButton className="myAccountBtn" onClick={editCompanyInfo}>{t('my-account.edit-info')}</CButton>
              </CCol>
            </CRow>
          </div>
        </CCol>
      </CRow>
      : ""}

        
        { invoices.length>0 && (user.permissions.indexOf("VIEW_CHECKOUTS") != -1) ?
          <CRow className="mt-5">
            <CCol>
              <h4 className="ms-3 mb-3">{t('my-account.invoices')}</h4>
              <div className="accountBox">
              { invoices.map((invoice, index) => (
                <CRow key={index}>
                  <CCol lg={2} className="d-flex align-items-top">
                    <CImage src={invoiceImg} alt="Invoice" height={25} />
                    <p className='ps-2 label'>{DateUtils.formatLocaleDate(invoice.createdAt, t('common.date-locale'))}</p>
                  </CCol>
                  <CCol lg={1}>
                    <p className="label">{invoice.amount+t('common.money-symbol')}</p>
                  </CCol>
                  <CCol lg={3}>
                    <p><b><a href={invoice.link} target="_blank" rel="noreferrer" className='linkNoDecoration'>{t('my-account.show-details')}</a></b></p>
                  </CCol>
                </CRow>
              ))}
              </div>
            </CCol>
          </CRow>
        : ''
        }

      	{/** Eliminar la cuenta
		 * DELETE /user
		*/}
        <CRow className="mt-5 mb-5 deleteAccount">
          <CCol>
            <h4 className="ms-3 mb-3">{t('my-account.remove-user-info')}</h4>
            <CButton className="btn ms-3 deleteButton" onClick={removeUser}>{t('my-account.remove-user')}</CButton>
          </CCol>
        </CRow>

        {/** Modal para editar la información de empresa */}
        <CModal alignment="center" size="lg" backdrop="static" visible={showModalEditCompany} onClose={() => setShowModalEditCompany(false)}>
          <CModalHeader>
            <CModalTitle>{t('my-account.edit-company')}</CModalTitle>
          </CModalHeader>
          <CModalBody>
            {companyValues ? 
            <CForm id="formEditCompany" onSubmit={handleEditCompany}>
              <CRow className="mb-4">
                <CCol sm={6}><CFormLabel htmlFor="formCompanyName">{t('my-account.company-name')}<span className='requiredField'>*</span></CFormLabel>
                  <CFormInput type="text" id="formCompanyName" name="name" value={companyValues.name} required
                      onChange={e => setCompanyValues({...companyValues, [e.target.name]: e.target.value})} ></CFormInput>
                </CCol>
                <CCol sm={6}>
                  <CFormLabel htmlFor="formEmail">{t('my-account.company-email')}<span className='requiredField'>*</span></CFormLabel>
                  <CFormInput type="text" id="formEmail" name="email" value={companyValues.email} required
                      onChange={e => setCompanyValues({...companyValues, [e.target.name]: e.target.value})} ></CFormInput>
                </CCol>
              </CRow>
              <CRow className="mb-4">
                <CCol sm={3}><CFormLabel htmlFor="formCif">{t('my-account.cif')}<span className='requiredField'>*</span></CFormLabel>
                  <CFormInput type="text" id="formCif" name="cif" value={companyValues.cif} required
                    onChange={e => setCompanyValues({...companyValues, [e.target.name]: e.target.value})} ></CFormInput>
                </CCol>
                <CCol>
                  <CFormLabel htmlFor="formStreet">{t('my-account.street')}<span className='requiredField'>*</span></CFormLabel>
                  <CFormInput type="text" id="formStreet" name="street" value={companyValues.street} required
                      onChange={e => setCompanyValues({...companyValues, [e.target.name]: e.target.value})} ></CFormInput>
                </CCol>
                <CCol sm={4}>
                  <CFormLabel htmlFor="formPhone">{t('my-account.phone')}</CFormLabel>
                  <CFormInput type="text" id="formPhone" name="phone" value={companyValues.phone}
                      onChange={e => setCompanyValues({...companyValues, [e.target.name]: e.target.value})} ></CFormInput>
                </CCol>
              </CRow>
              <CRow className="mb-4">
                <CCol sm={3}>
                  <CFormLabel htmlFor="formCP">{t('my-account.cp')}<span className='requiredField'>*</span></CFormLabel>
                  <CFormInput type="number" id="formCP" name="cp" value={companyValues.cp} required
                      onChange={e => setCompanyValues({...companyValues, [e.target.name]: e.target.value})} ></CFormInput>
                </CCol>
                <CCol sm={5}>
                  <CFormLabel htmlFor="formCity">{t('my-account.city')}<span className='requiredField'>*</span></CFormLabel>
                  <CFormInput type="text" id="formCity" name="city" value={companyValues.city} required
                      onChange={e => setCompanyValues({...companyValues, [e.target.name]: e.target.value})} ></CFormInput>
                </CCol>
                <CCol sm={4}>
                  <CFormLabel htmlFor="formCountry">{t('my-account.country')}</CFormLabel>
                  <CFormInput type="text" id="formCountry" name="country" value={companyValues.country}
                      onChange={e => setCompanyValues({...companyValues, [e.target.name]: e.target.value})} ></CFormInput>
                </CCol>
              </CRow>
              <div className="mt-4">
                  <CButton type="submit">{t('common.save')}</CButton>
                  <CButton onClick={() => setShowModalEditCompany(false)} color="secondary" className="ms-2">{t('common.cancel')}</CButton>
              </div>
            </CForm>
            : ''
            }
          </CModalBody>
        </CModal>
      </div>
    </>
    }
  </>
  )
}

/** REDUX EXPORT */

// Puede acceder al estado global
const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}

const ConnectedMyAccount = connect(
  mapStateToProps
)(MyAccount)

export default ConnectedMyAccount;