import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, size } from 'lodash';
import { useTranslation } from 'react-i18next'
import { CButton, 
	CContainer,
	CForm, 
	CFormInput,
	CImage, 
	CRow,
	CSpinner } from '@coreui/react';
import { loginCall, meCall } from '../api/endpoints';
import { ToastContainer } from 'react-toastify';
import { ToastNotify } from '../components/ToastNotify';

import logoImg from '../assets/images/spotileads_positivo.png'
import hidePassImg from '../assets/images/hide-pass.png'
import revealPassImg from '../assets/images/reveal-pass.png'
import { SessionApi } from '../api/SessionApi';

const Login = (props: any) => {

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const lastRequestTstp = useSelector((state: any) => state.changeState.lastRequestTstp);

	const user = useSelector((state: any) => state.auth.user);
	
	const defaultFormValue = () => {
		return {
			email: "",
			password: ""
		}
	}
	const { t } = useTranslation()
	
	const [showPass, setShowPass] = useState(false);
	const [showResendEmail, setShowResendEmail] = useState(false);
	const [formData, setformData] = useState(defaultFormValue());
	const [loading, setLoading] = useState(false);
	const [loadingSpinner, setLoadingSpinner] = useState("");

	useEffect(() => {
		if(user) {
			navigate("/dashboard");
		}
	}, [user])

	//Listening for logging in process, for showing spinner
	useEffect(() => {
		loading
		? setLoadingSpinner(getSpinner)
		: setLoadingSpinner("");
	}, [loading])
	const getSpinner: any = ( <CSpinner component="span" size="sm" aria-hidden="true" color="warning" /> )

	// Envio a la api de los datos para hacer login
	const onSubmit = async () => {
		if ((isEmpty(formData.email) || isEmpty(formData.password)) ) {
			ToastNotify.error(t('login.error-fields-mandatory'));
		} else if (size(formData.password) < 6 ) {
			ToastNotify.error(t('login.error-password-minimum'));
		} else {
			setLoading(true);
			try {
				await loginCall(formData.email, formData.password);
				await meCall();
				createSession()
				navigate("/");
			} catch(error) {
				if(error.includes("Incorrect password")) {
					ToastNotify.error(t('login.error-invalid-password'));
				} else if(error.includes("email must be an email")) {
					ToastNotify.error(t('register.error-incorrect-email'));
				} else if(error.includes("User with email")) {
					const userEmail = error.replace("User with email: ","").replace(" not found.", "");
					ToastNotify.error(t('login.error-email-not-found', { email: userEmail }));
				} else {
					if(error.includes("3011")) {
						setShowResendEmail(true);
					}
					ToastNotify.error(t('remote.'+ error) );
				}
				setLoading(false);
				return;
			}
			// { access_token: string, refresh_token: string, expires_in: number, token_type: string, scope: any }
			setLoading(false);
			setformData(defaultFormValue());
		}
	}

	const createSession = async () => {
		let sessionId = localStorage.getItem('sessionID');
		//If there is sessionID in LS, must close previous session
		if( sessionId ) {
			try {
				await SessionApi.closeSession(sessionId, new Date(lastRequestTstp));
			} catch (error) {
				localStorage.removeItem('sessionID');
				localStorage.removeItem('sessionTSTP');
			}
			dispatch({ type: 'session', lastRequestTstp: 0 });
			localStorage.removeItem('sessionID');
			localStorage.removeItem('sessionTSTP');
		}
		
		try {
			sessionId = await SessionApi.createSession();
			localStorage.setItem('sessionID', sessionId);
		} catch (error) {
			console.log("Cannot create new session...");
		}
		
	}

	// Cada vez que cambian los datos del formulario
	const onChange = (e: any, fieldName: string) => {
		setformData({ ...formData, [fieldName]: e.nativeEvent.target.value });
	}

	//Event for reveal or hide password
	const onClickRevealPass = () => {
		setShowPass(!showPass)
	}

	return (
		<CContainer sm className="loginContainer">
			<div className="loginWrapper">
				<div className="text-center logoWrapper"><CImage src={logoImg} className="mb-4" /></div>
				<CForm className="loginForm mt-2">
					<div className="mb-4">
						<CFormInput onChange={(e) => onChange(e, "email")} type="email" 
							id="inputEmail" placeholder={t('login.email')} />
						
						{ (showResendEmail && formData.email) ?
						<div className="text-center mt-1">
							<NavLink to="/resend-confirmation" className="registerLink">
								{t('login.resend-email')}
							</NavLink>
						</div>
						: ""
						}

					</div>
					<div className="mb-2 d-flex align-items-center">
						<div className="flex-grow-1">
							<CFormInput onChange={(e) => onChange(e, "password")} type={showPass ? "text" : "password"} 
								id="inputPassword" placeholder={t('login.password')} />
						</div>
						<div>
							{showPass
							? <CImage id="imgRevealPass" src={hidePassImg} 
									onClick={() => onClickRevealPass()} className="isLink" />
							: <CImage id="imgRevealPass" src={revealPassImg}
									onClick={() => onClickRevealPass()} className="isLink" />}
						</div>
					</div>
					<CRow className="mb-4">
						<ToastContainer />
					</CRow>
					<CButton onClick={() => onSubmit()} className="mb-3 submitLogin">
						{t('login.submit')}{loadingSpinner}
					</CButton>

					<div className="text-center mt-1">
						<p className="mb-0">{t('login.not-registered')}</p>
						<NavLink to="/register" className="registerLink">{t('login.register-here')}</NavLink>
					</div>
					<div className="text-center mt-3">
						<p className="mb-0">{t('login.forbidden-password')}</p>
						<NavLink to="/rememberPassword" className="registerLink">{t('login.forbidden-password-recover')}</NavLink>
					</div>
				</CForm>
			</div>
		</CContainer>
	);
}

export default Login;
