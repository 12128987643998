import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom'
import { isEmpty, size } from 'lodash';
import { ToastContainer } from 'react-toastify';
import { ToastNotify } from '../components/ToastNotify';
import { useTranslation } from 'react-i18next'
import { CButton, 
	CCol, 
	CContainer,
	CForm, 
	CFormInput,  
	CFormLabel,  
	CImage, 
	CRow,
	CSpinner } from '@coreui/react';
import { registerCall } from '../api/endpoints';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import logoImg from '../assets/images/spotileads_positivo.png'
import hidePassImg from '../assets/images/hide-pass.png'
import revealPassImg from '../assets/images/reveal-pass.png';
import brandImg from '../assets/images/etiqueta.png';
import { MarcasApi } from '../api/MarcasApi';
import { useDispatch } from 'react-redux';

const Register = (props: any) => {

	  //For Multi Select (react-select lib)
	  const animatedComponents = makeAnimated();


	const navigate = useNavigate();
	const dispatch = useDispatch();
	
	const defaultFormValue = () => {
		return {
			email: "",
			password: "",
			name: "",
			companyName: ""
		}
	}
	const { t } = useTranslation()
	
	const [brands, setBrands] = useState([]);
	const [selectedBrands, setSelectedBrands] = useState(null);
	const [cpValue, setCpValue] = useState(null);

	const [showPass, setShowPass] = useState(false);
	const [formData, setformData] = useState(defaultFormValue());
	const [loading, setLoading] = useState(false);
	const [loadingSpinner, setLoadingSpinner] = useState("");
	const [disableMarcas, setDisableMarcas] = useState(false);

	useEffect(() => {
		console.log('hola 1')
		let isMounted = true;
		(async () => {
		  try {
			console.log('hola 2')
			const allBrands = await MarcasApi.getAll();
			if(isMounted) {
				console.log('hola 3', allBrands)
			  setBrands(allBrands);
			}
		  } catch (error) {
			dispatch({ type: 'set', errorMessage: error });
			dispatch({ type: 'set', showError: true });
		  }
		  
		})()
		return () => { isMounted = false };
	  }, []);

	 //Listening for disableMarcas, for showing spinner while running async task of updating user brands
	 useEffect(() => {
		disableMarcas
		? setLoadingSpinner(getSpinner)
		: setLoadingSpinner("");
	}, [disableMarcas])

	//Listening for logging in process, for showing spinner
	useEffect(() => {
		loading
		? setLoadingSpinner(getSpinner)
		: setLoadingSpinner("");
	}, [loading])
	const getSpinner: any = ( <CSpinner component="span" size="sm" aria-hidden="true" color="warning" /> )

	// Envio a la api de los datos para hacer login
	const onSubmit = async () => {
		if ((isEmpty(formData.email) || isEmpty(formData.password) || isEmpty(formData.name) || isEmpty(formData.companyName) ) ) {
			ToastNotify.error(t('login.error-fields-mandatory'));
		} else if (size(formData.password) < 6 ) {
			ToastNotify.error(t('login.error-password-minimum'));
		} else {
			setLoading(true);
			try {
				const registerResponse = await registerCall(
					formData.email, 
					formData.password, 
					formData.name, 
					formData.companyName,
					selectedBrands,
					cpValue
				);
					ToastNotify.success(t('remote.'+registerResponse.code));
				setTimeout(() => {
					setLoading(false);
					//navigate("/login");
				}, 2500);
			} catch(error) {
				ToastNotify.error(t('remote.'+error));
				setLoading(false);
				return;
			}
			// { access_token: string, refresh_token: string, expires_in: number, token_type: string, scope: any }
			setLoading(false);
			setformData(defaultFormValue());
		}
	}

	// Cada vez que cambian los datos del formulario
	const onChange = (e: any, fieldName: string) => {
		setformData({ ...formData, [fieldName]: e.nativeEvent.target.value });
	}

	//Event for reveal or hide password
	const onClickRevealPass = () => {
		setShowPass(!showPass)
	}

	return (
		<CContainer sm className="registerContainer">
			<div className="registerWrapper">
				<div className="text-center logoWrapper"><CImage src={logoImg} className="mb-3" /></div>
				<CForm className="loginForm mt-2">
					<div className="mb-4">
						<CFormInput onChange={(e) => onChange(e, "email")} type="email" 
							id="inputEmail" placeholder={t('login.email')} />
					</div>
					<div className="mb-4">
						<CFormInput onChange={(e) => onChange(e, "name")} type="text" 
							id="inputName" placeholder={t('register.name')} />
					</div>
					<div className="mb-4 d-flex align-items-center">
						<div className="flex-grow-1">
							<CFormInput onChange={(e) => onChange(e, "password")} type={showPass ? "text" : "password"} 
								id="inputPassword" placeholder={t('login.password')} />
						</div>
						<div>
							{showPass
							? <CImage id="imgRevealPass" src={hidePassImg} 
									onClick={() => onClickRevealPass()} className="isLink" />
							: <CImage id="imgRevealPass" src={revealPassImg} 
									onClick={() => onClickRevealPass()} className="isLink" />}
						</div>
					</div>

					{/* Nombre de la empresa */}

					<div className="mb-3">
						<CFormInput onChange={(e) => onChange(e, "companyName")} type="text" 
							id="companyName" placeholder={t('register.companyName')} />
					</div>

					<CRow className="mt-4">
						<CCol sm={8} className="accountGroup">
						<div className="d-flex">
							<div className="ms-2 mb-3">
								<p className="label white">{t('my-account.cp')}</p>
							</div>
						</div>
						<CFormInput type="number" name="cp" value={cpValue} required
							onChange={e => setCpValue(e.target.value)} ></CFormInput>
						</CCol>
                    </CRow>

					{/* Marcas en las que esta interesado */}

					<CRow className="mt-3">
						<CCol sm={8} className="accountGroup">
						<div className="d-flex">
							<div className="ms-2 mb-3">
								<p className="label white">{t('my-account.brands')}</p>
							</div>
						</div>
						<Select closeMenuOnSelect={false}
							components={animatedComponents}
							defaultValue={[]}
							isMulti
							isClearable={false}
							isDisabled={disableMarcas}
							options={brands}
							placeholder={t('my-account.select')}
							onChange={(values) => setSelectedBrands(values)} />
						</CCol>
						<CCol className='d-flex align-items-end pb-2'>{loadingSpinner}</CCol>
					</CRow>

					


					<CRow className="mb-4">
						<ToastContainer />
					</CRow>
					<CButton onClick={() => onSubmit()} className="mb-3 submitLogin">
						{t('register.submit')} {loadingSpinner}
					</CButton>

					<div className="text-center mt-1">
						<p className="mb-0">{t('register.already-registered')}</p>
						<NavLink to="/login" className="registerLink">{t('register.login-here')}</NavLink>
					</div>
				</CForm>
			</div>
		</CContainer>
	);
}

export default Register;
