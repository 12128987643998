import React from 'react'

const PanelAdmin = React.lazy(() => import('../pages/PanelAdmin'));
const Dashboard = React.lazy(() => import('../pages/Dashboard'));
const Clients = React.lazy(() => import('../pages/admin/Clients'));
const Statistics = React.lazy(() => import('../pages/Statistics'));
const UserInfo = React.lazy(() => import('../pages/admin/UserInfo'));
const Report = React.lazy(() => import('../pages/admin/Report'));
const Admin = React.lazy(() => import('../pages/Admin'));
const AdminStatistics = React.lazy(() => import('../pages/StatisticsAdmin'));
const LeadCard = React.lazy(() => import('../pages/LeadCard'));
const LeadCardAdmin = React.lazy(() => import('../pages/LeadCardAdmin'));

const routes = [
    { path: '/', name: 'Dashboard', element: Dashboard },
    { path: '/dashboard', name: 'Dashboard', element: Dashboard },
    { path: '/clients', name: 'Lead card', element: Clients },
    { path: '/userInfo/:userId', name: 'User card', element: UserInfo },
    { path: '/adminLeads', name: 'Admin Leads', element: PanelAdmin },
    { path: '/statistics', name: 'Lead card', element: Statistics },
    { path: '/admin-statistics', name: 'Lead card', element: AdminStatistics },
    { path: '/report', name: 'Lead card', element: Report },
    { path: '/admin', name: 'Leads admin', element: Admin },
    { path: '/card/:leadId', name: 'Lead card', element: LeadCard },
    { path: '/card-admin/:leadId', name: 'Lead card admin', element: LeadCardAdmin }
  ]
  
  export default routes
  