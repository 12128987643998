import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { LeadsApi } from '../api/LeadsApi'
import { DateUtils } from '../utils/DateUtils'
import DataTable from 'react-data-table-component'
import DataTableFiltersMyLeads, { initialFilterValues } from './DataTableFiltersMyLeads'
import { ContactsRate } from './ContactsRate'
import { FavoriteCheckbox } from './FavoriteCheckbox'

import { CPopover } from '@coreui/react';


const DataTableMyLeads = ({ data, loading, setReload }) => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [filterValues, setfilterValues] = useState(initialFilterValues);
    
    //Methods for handle FavoriteCheckbox child component
    const addFavoriteLead = async (id) => {
      try {
        await LeadsApi.addFavorite( id )
        setReload(true)
      } catch (error) {
        console.log(error)
      }
    }

    const removeFavoriteLead = async (id) => {
        try {
          await LeadsApi.removeFavorite( id )
          setReload(true)
        } catch (error) {
          console.log(error)
        }
    }

    //Methods for handle AppFilterComponent, for updating datatable content
    const handleFilterChange = async (newValues) => {

      setfilterValues({...newValues})
    }

    const handleClear = () => {
      setResetPaginationToggle(!resetPaginationToggle)
    }

    //Show lead info card
    const openLeadCard = (row, event) => {
      navigate("/card/"+row.id)
    };

    const getEstado = (row) => {
      if(row.borrado) return 'BORRADO';
      if(row.vendido) return 'VENDIDO';
      
      return row.lastAction.title
    }

    const getDesc = (row) => {
      if(row.borrado) return 'EL lead ha sido borrado';
      if(row.vendido) return 'El lead ya ha sido vendido';
      
      return row.lastAction.description
    }

    //Const for datatable configuration
    const tableColumns = [
        {
          name: t('datatable.date'),
          selector: row => row.date,
          center: true,
          compact: true,
          sortable:true
        },
        {
          name: t('datatable.name'),
          selector: row => row.name,
          center: true,
          compact: true,
          sortable:true
        },
        {
          name: t('datatable.surname'),
          selector: row => row.surname,
          center: true,
          compact: true,
          sortable:true
        },
        {
          name: t('datatable.brand'),
          selector: row => row.brand,
          center: true,
          compact: true,
          sortable:true
        },
        {
          name: t('datatable.model'),
          selector: row => row.model,
          center: true,
          compact: true,
          sortable:true
        },
        {
          name: t('datatable.segment'),
          selector: row => row.segment,
          center: true,
          compact: true,
          sortable:true
        },
        {
          name: t('datatable.last-action'),
          selector: row => getEstado(row),
          cell: row => <CPopover
                content={getDesc(row)}
                placement="right"
                offset={[0, 15]}
                trigger="hover"><div>{getEstado(row)}</div></CPopover>,
          center: true,
          compact: true,
        },
        {
          name: t('datatable.ncontacts'),
          selector: row => row.contactsNumber,
          cell: row => <ContactsRate contacts={row.contactsNumber} />,
          center: true,
          compact: true,
        },
        {
          name: t('datatable.province'),
          selector: row => row.province,
          center: true,
          compact: true,
        },
        {
          name: '',
          selector: row => row.favorite,
          cell: row => <FavoriteCheckbox id={row.id} 
                            checked={row.favorite} 
                            addFavorite={addFavoriteLead} 
                            removeFavorite={removeFavoriteLead} />,
          center: true,
          compact: true,
          minWidth: '4rem',
          maxWidth: '4rem',
        },
      ]
    
    //Format DB data to DataTable format
    const tableData = data.map((item, index) => (
        {
            id: item.id,
            closed: item.closed,
            vendido: item.vendido,
            date: DateUtils.formatLocaleDate(item.fechaAlta, t('common.date-locale')),
            name: item.name,
            surname: item.surname,
            brand: item.brand,
            model: item.model,
            segment: item.type,
            borrado: item.borrado,
            lastAction: item.globalStatus ?? item.lastAction,
            contactsNumber: item.contactsNumber,
            province: item.province,
            favorite: item.favorito,
        }
    ))

    const paginationOptions = {
      rowsPerPageText: t('datatable.page-rows'),
      rangeSeparatorText: t('datatable.range-separator'),
      selectAllRowsItem: true,
      selectAllRowsItemText: t('datatable.all-rows'),
    }

    const customStyles = {
      headCells: {
        style: {
          color: '#FFFFFF',
          backgroundColor: '#4B83C3',
        },
      },
      subHeader: {
        style: {
          alignItems: 'start',
          justifyContent: 'start',
        },
      },
    }

    //Dynamic data filtering, managed by DataTable lib
    const filteredItems = tableData.filter(item => {

        //If no filter criteria, add to filteredItems
        if(filterValues == null) return true;
        if(
          filterValues.brand.value==='-1' && 
          filterValues.model.value==='-1' && 
          filterValues.segment.value==='-1' && 
          filterValues.province.value==='-1' && 
          filterValues.date.value==='-1' && 
          filterValues.lastAction.value==='-1' && 
          filterValues.favorite===false && 
          filterValues.all===true
        ) {
          return true;
        }
        //Filter for each criteria
        if (filterValues.brand.value!=='-1') {
          if(item.brand.toLowerCase().trim() !== filterValues.brand.label.toLowerCase().trim())
            return false;
        }
        if(filterValues.model.value!=='-1') {
          if(item.model.toLowerCase().trim() !== filterValues.model.label.toLowerCase().trim())
            return false;
        }
        if(filterValues.segment.value!=='-1') {
          if(item.segment.toLowerCase().trim() !== filterValues.segment.label.toLowerCase().trim())
            return false;
        }
        if(filterValues.province.value!=='-1') {
          if(item.province.toLowerCase().trim() !== filterValues.province.label.toLowerCase().trim())
            return false;
        }
        if(filterValues.date.value!=='-1') {
          //Convert dates for comparing Date objects
          const dateParts = item.date.split("/");
          const itemDate = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
          const dateParts2 = filterValues.date.value.split("/");
          const filterDate = new Date(+dateParts2[2], +dateParts2[1] - 1, +dateParts2[0]);
          if(itemDate < filterDate)
            return false;
        }
        if (filterValues.lastAction.value!=='-1') {
          if(item.lastAction.type.toLowerCase().trim() !== filterValues.lastAction.value.toLowerCase().trim())
            return false;
        }
        if(filterValues.favorite===true) {
          if(item.favorite !== filterValues.favorite)
            return false;
        }
        if(filterValues.all===false) {
          if(item.closed || item.borrado) {
            return false;
          }
        }
        return true;
    })
  
    return (
        <>
          <DataTable
              pagination
              columns={tableColumns}
              customStyles={customStyles}
              data={filteredItems}
              highlightOnHover={true}
              onRowClicked={openLeadCard}
              paginationComponentOptions={paginationOptions}
              paginationPerPage={25}
              paginationRowsPerPageOptions={[25, 50, 100]}
              paginationResetDefaultPage={resetPaginationToggle}
              noDataComponent={<div className="pb-4">{t('datatable.no-records-to-display')}</div>}
              progressPending={loading}
              subHeader
              subHeaderComponent={<DataTableFiltersMyLeads 
                                    handleChange={handleFilterChange} 
                                    handleReset={handleClear} />}
          />
        </>
    )
}

export default DataTableMyLeads