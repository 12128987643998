import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { CContainer } from '@coreui/react';
import { LeadsApi } from '../api/LeadsApi';
import { useTranslation } from 'react-i18next';

const Confirmation = (props: any) => {

	const { t } = useTranslation()
	let [searchParams, setSearchParams] = useSearchParams();
	let [messageCode, setMessageCode] = useState('0001');

	//Listening for logging in process, for showing spinner
	useEffect(() => {
		let isMounted = true;
      	(async () => {
			if(searchParams.get("result") !== null && searchParams.get("id") !== null){
				if(isMounted) {
					try {
						const response = await LeadsApi.confirmLead(searchParams.get("id"), (searchParams.get("result") === "true"));
						
						setMessageCode(response.code);
					} catch (error) {
						setMessageCode(error);
					}
				}
			} else {

			}
		})();
		return () => { isMounted = false };
	}, [])


	return (
		<CContainer sm className="loginContainer">
			<div className="loginWrapper">
				<h1 className="text-center mb-5 loginHeader">
					{t('remote.'+messageCode)}
				</h1>
			</div>
		</CContainer>
	);
}

export default Confirmation;
