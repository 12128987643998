import { useEffect, useState } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { CButton, CContainer, CForm, CFormInput, CImage, CRow } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { ToastContainer } from 'react-toastify';
import { ToastNotify } from '../components/ToastNotify';
import { UserApi } from '../api/UserApi';

import logoImg from '../assets/images/spotileads_positivo.png'

const RememberPassword = (props: any) => {

	const defaultFormValue = () => {
		return {
			email: ""
		}
	}

	const { t } = useTranslation()
	let [searchParams, setSearchParams] = useSearchParams();
	let [messageCode, setMessageCode] = useState('0010');

	const [token, setToken] = useState(null);
	const [formData, setformData] = useState(defaultFormValue());
	const [loading, setLoading] = useState(false);
	const [loadingSpinner, setLoadingSpinner] = useState("");

	useEffect(() => {
		let isMounted = true;
      	(async () => {
			if(searchParams.get("token") !== null){
				if(isMounted) {
					setToken(searchParams.get("token"))
				}
			} else {
				// Error no token
			}
		})();
		return () => { isMounted = false };
	}, [])

	const onChange = (e: any, fieldName: string) => {
		setformData({ ...formData, [fieldName]: e.nativeEvent.target.value });
	}

	const onSubmit = async () => {
		if ((isEmpty(formData.email)) ) {
			ToastNotify.error(t('login.error-fields-mandatory'));
		} else {
			setLoading(true);
			try {
				   const response = await UserApi.recoverPasswordSendEmail(formData.email);

					ToastNotify.success(t('remote.'+response.code));
			} catch(error) {
				ToastNotify.error(t('remote.'+error));
				setLoading(false);
				return;
			}
			setLoading(false);
			setformData(defaultFormValue());
		}
	}

	return (
		<CContainer sm className="loginContainer">
			<div className="loginWrapper">
				<div className="text-center logoWrapper"><CImage src={logoImg} className="mb-3" /></div>
				<p className="text-center mt-5 mb-4">
					{t('remote.'+messageCode)}
				</p>
				<CForm className="loginForm mt-2">
					<div className="mb-3">
						<CFormInput onChange={(e) => onChange(e, "email")} type="email" id="inputEmail" 
							placeholder={t('login.email')} />
					</div>
					<CRow className="mb-3">
						<ToastContainer />
					</CRow>
					<CButton onClick={() => onSubmit()} className="mb-3 submitLogin">
						{t('common.send')} {loadingSpinner}
					</CButton>

					<div className="text-center mt-1">
						<NavLink to="/login" className="registerLink">{t('register.login-here')}</NavLink>
					</div>
				</CForm>
				
			</div>
		</CContainer>
	);
}

export default RememberPassword;
