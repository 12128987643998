import { LOGOUT_REQUEST } from "../contexts/actions/userActions";
import store from "../contexts/store";
import { LeadActionsDto } from "../interfaces/Api";
import { authApiCall } from "./apiCalls";

/** Recupera los leads dados de alta para el usuario */
const getAllLeads = (): Promise<any> => {
	return new Promise((resolve, reject) => {
		const params = { };
		authApiCall.get("/leads", { params })
			.then(({ data, status }) => { resolve(data) })
			.catch((error) => { reject(processError(error)) })
	});
}

// Confirmamos que un lead ha sido vendido
const confirmLead = (id: string, result: boolean): Promise<any> => {
	return new Promise((resolve, reject) => {
		const params = { id, result };
		authApiCall.post("/leads/confirmation", params )
			.then(({ data, status }) => { resolve(data) })
			.catch((error) => { reject(processError(error)) })
	});
}

const removeLead = (id: string, result: boolean, token: string): Promise<any> => {
	return new Promise((resolve, reject) => {
		const params = { id, result, token };
		authApiCall.post("/leads/remove", params )
			.then(({ data, status }) => { resolve(data) })
			.catch((error) => { reject(processError(error)) })
	});
}

const getLeadActions = (id): Promise<LeadActionsDto> => {
	return new Promise((resolve, reject) => {
        const params = { id };
		authApiCall.get("/action", {params})
        .then(({ data, status }) => { resolve(data) })
        .catch((error) => { reject(processError(error)) })
	});
}

const getLead = (id): Promise<any> => {
	return new Promise((resolve, reject) => {
        const params = { id };
		authApiCall.get("/leads/lead", {params})
			.then(({ data, status }) => { resolve(data) })
			.catch((error) => { reject(processError(error)) })
	});
}

const addFavorite = (leadId: string): Promise<any> => {
	return new Promise((resolve, reject) => {
		if (!leadId) {
            reject('lead ID is required');
        }
        const params = { id: leadId };
		authApiCall.post("/leads/favorites", params )
			.then(({ data, status }) => {
				resolve(data);
			})
			.catch((error) => {reject(processError(error))})
	});
}

/** Añade el lead al usuario (compra de un lead) */
const addUserLead = (leadId: string): Promise<any> => {
	return new Promise((resolve, reject) => {
        const params = { id: leadId };
		authApiCall.post("/user/leads", params )
			.then(({ data })  => resolve(data))
			.catch((error) => reject(processError(error)))
	});
}

const contactLead = (leadId: string): Promise<any> => {
	return new Promise((resolve, reject) => {
		if (!leadId) reject('lead ID is required');
        const params = {leadId};
		authApiCall.post("/leads/contact", params )
			.then(({ data, status }) => { resolve(data) })
			.catch((error) => { reject(processError(error)) })
	});
}

const emailLead = (leadId: string, subject: FormDataEntryValue, message: FormDataEntryValue): Promise<any> => {
	return new Promise((resolve, reject) => {
		if (!leadId) {
            reject('lead ID is required');
        }
        
        const params = {
            leadId,
            subject,
            body: message
        };
		authApiCall.post("/leads/contactemail", params )
			.then(({ data, status }) => resolve(data))
			.catch(error => reject(processError(error)))
	});
}

const getUserLeads = (): Promise<any> => {
	return new Promise((resolve, reject) => {
		authApiCall.get("/user/leads")
			.then(({ data, status }) => { resolve(data) })
			.catch((error) => { reject(processError(error)) })
	});
}

const getLeadsOfUser = (userId: string): Promise<any> => {
	const params = {id: userId};
	return new Promise((resolve, reject) => {
		authApiCall.get("/leads/ofUser", {params})
			.then(({ data, status }) => { resolve(data) })
			.catch((error) => { reject(processError(error)) })
	});
}


const haveFailedLeads = (): Promise<any> => {
	return new Promise((resolve, reject) => {
		authApiCall.get("/leads/haveFailedLeads")
			.then(({ data, status }) => { resolve(data) })
			.catch((error) => { reject(processError(error)) })
	});
}


const getUserFailedLeads = (): Promise<any> => {
	return new Promise((resolve, reject) => {
		authApiCall.get("/leads/myFailedleads")
			.then(({ data, status }) => { resolve(data) })
			.catch((error) => { reject(processError(error)) })
	});
}

const getFavorites = (): Promise<any> => {
	return new Promise((resolve, reject) => {
		authApiCall.get("/leads/favorites")
			.then(({ data, status }) => { resolve(data) })
			.catch((error) => { reject(processError(error)) })
	});
}

const getFilterActions = (all: boolean): Promise<any> => {
	const params = {all};
	return new Promise((resolve, reject) => {
		authApiCall.get("/action/filter/available", {params})
			.then(({ data, status }) => { resolve(data) })
			.catch((error) => { reject(processError(error)) })
	});
}

const getActions = (): Promise<any> => {
	return new Promise((resolve, reject) => {
		authApiCall.get("/action/available")
			.then(({ data, status }) => { resolve(data) })
			.catch((error) => { reject(processError(error)) })
	});
}

const sendAction = (
	leadId: string, 
	type: string, 
	description?: string
): Promise<any> => {
	return new Promise((resolve, reject) => {
        const params = {
            leadId,
            type,
            description
        };
		authApiCall.post("/action", params)
			.then(({ data, status }) => { resolve(data) })
			.catch((error) => { reject(processError(error)) })
	});
}

const importLeads = (): Promise<any> => {
	return new Promise((resolve, reject) => {
		authApiCall.post("/leads/raw")
			.then(({ data, status }) => { resolve(data) })
			.catch((error) => { reject(processError(error)) })
	});
}

const resetDatabase = (): Promise<any> => {
	return new Promise((resolve, reject) => {
		authApiCall.post("/admin/reset-database")
			.then(({ data, status }) => { resolve(data) })
			.catch((error) => { reject(processError(error)) })
	});
}

const importMarcas = (): Promise<any> => {
	return new Promise((resolve, reject) => {
		authApiCall.post("/marca/bulk")
			.then(({ data, status }) => { resolve(data) })
			.catch((error) => { reject(processError(error)) })
	});
}

const removeFavorite = (leadId: string): Promise<any> => {
	return new Promise((resolve, reject) => {
		if (!leadId) {
            reject('lead ID is required');
        }
        const data = {
            id: leadId
        };
		authApiCall.delete("/leads/favorites", {data} )
        .then(({ data, status }) => { resolve(data) })
        .catch((error) => { reject(processError(error)) })
	});
}

const isMyLead = (leadId: string): Promise<any> => {
	return new Promise((resolve, reject) => {
        const params = { id: leadId };
		authApiCall.get("/leads/mylead", {params})
			.then(({ data, status }) => { resolve(data) })
			.catch((error) => { reject(processError(error)) })
	});
}

const isFavorite = (leadId: string): Promise<any> => {
	return new Promise((resolve, reject) => {
        const params = { id: leadId };
		authApiCall.get("/leads/isFavorite", {params})
			.then(({ data, status }) => { resolve(data) })
			.catch((error) => { reject(processError(error)) })
	});
}

const getAllModels = (brandName): Promise<Array<any>> => {
    return new Promise((resolve, reject) => {
        const params = {
            name: brandName
        };
		authApiCall.get("/marca/modelos", {params})
			.then(({ data, status }) => {
                const response =  [{ label: 'Modelo', value: '-1' }];
                data.forEach(dataElement => {
                    response.push({ label: dataElement, value: dataElement });
                });
				resolve(response);
			})
			.catch((error) => {
				reject(processError(error));
			})
	});
}

const getAllProvinces = (): Promise<Array<any>>  => {
    return new Promise((resolve, reject) => {
		authApiCall.get("/provincia")
			.then(({ data, status }) => { resolve(data) })
			.catch((error) => { reject(processError(error)) })
	});
}

const getAllProvincesFromMarca = (
    marca: string, 
    segmento?: string
): Promise<any> => {
    const params = {
        marca,
        segmento
    };
    return new Promise((resolve, reject) => {
		authApiCall.get("/provincia/fromMarca", {params})
			.then(({ data, status }) => { resolve(data) })
			.catch((error) => { reject(processError(error)) })
	});
}

const getAllMyProvinces = (): Promise<Array<any>>  => {
    return new Promise((resolve, reject) => {
		authApiCall.get("/provincia/misprovincias")
			.then(({ data, status }) => {
                const response =  [{ label: 'Provincia', value: '-1' }];
                data.forEach(dataElement => {
                    response.push({ label: dataElement, value: dataElement });
                });
				resolve(response);
			})
			.catch((error) => {
				reject(processError(error));
			})
	});
}

const getAllContacts = () => {
    return [
        { label: 'Contactos', value: '-1' },
        { label: '0', value: '0' },
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' }
    ]
}

const processError = (error: any) => {
    if (error.response) {
        const serverError = error.response.data;
        if(serverError.statusCode === 403) {
            store.dispatch({type: LOGOUT_REQUEST.type});
            return 'error.0003';
        }
		else if(serverError.statusCode === 500) {
            return 'error.0001';
        }
		else if(serverError.statusCode === 400) {
            return 'error.'+serverError.message;
        }
        return serverError.message;
    } else if (error.request) {
        return 'error.0002';
    } else {
        return 'error.0001';
    }
}

export const LeadsApi = {
    getAllMyProvinces,
    addFavorite,
    resetDatabase,
    getFilterActions,
    addUserLead,
    getAllLeads,
    contactLead,
    emailLead,
    getAllModels,
    isMyLead,
    getActions,
    getLeadActions,
    sendAction,
    getUserLeads,
    getFavorites,
    getAllProvinces,
    getAllContacts,
    getLead,
    removeFavorite,
    importMarcas,
    importLeads,
    getUserFailedLeads,
    haveFailedLeads,
    getAllProvincesFromMarca,
    confirmLead,
	removeLead,
	getLeadsOfUser,
	isFavorite
  };