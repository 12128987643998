import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux';
import {
  CAlert,
  CContainer,
  CHeader,
  CHeaderNav,
  CNavItem,
  CProgress,
  CProgressBar,
} from '@coreui/react';

const AppHeader = () => {

  const { t } = useTranslation()
  const dispatch = useDispatch();

  const user = useSelector((state: any) => state.auth.user);

  const [percentage, setPercentage] = useState(0);
  const [remainingLeads, setRemainingLeads] = useState(0);
  const [leadsLimit, setLeadsLimit] = useState(0);

  useEffect(() => {
    if( user ) {
      const leadsToUse = user.plan.leadsRemain ? user.plan.leadsRemain : 0;
      const maxLeads = user.plan.leadsLimit ? user.plan.leadsLimit : 0;
      const percent = 100*leadsToUse/maxLeads;
      setPercentage(Math.round(percent))
      setRemainingLeads(leadsToUse)
      setLeadsLimit(maxLeads)
    }
  }, [user])

  

  const buyPlan = () => {
    dispatch({ type: 'plans', showPlans: true });
  }

  const buyProduct = () => {
    dispatch({ type: 'products', showProducts: true });
  }

  return (
    <>
    { user ?
      <CHeader position="sticky" className="mb-2">
        <CContainer fluid>
          { user.role === 'user' ?
            <CHeaderNav>
              { user.company.monthlySubscriptionStatus == "active" ?
              <CNavItem className="navitemRemainingLeads">
                <div className="text-end">
                  <p className="mt-3 mb-0 remainingLeads">{ t('header.remaining', { remaining: remainingLeads })}</p>
                  { user.plan.extraLeads>0   ?
                    <p className='mb-0'>{t('header.extra-leads', { qtty: user.plan.extraLeads })}</p>
                  :''
                  }
                </div>
              </CNavItem>
              : <></>
              }
              { //Si le quedan leads, mostrar barra de progreso
                percentage > 0 ?
                <>
                <CNavItem className="ms-4 navitemProgressbar">
                    <CProgress>
                        <CProgressBar value={percentage}></CProgressBar>  
                    </CProgress>
                </CNavItem>
                {
                    user.permissions.indexOf("EXTRA_LEADS") != -1
                      ? <CNavItem className="navitemBuyLeads">
                          <CAlert color="succes" onClick={buyProduct}>{t('header.buy-leads')}</CAlert>
                        </CNavItem>
                      : <></>
                }
                </>
              : //Si no le quedan leads:
                <CNavItem className="ms-4 navitemProgressbar">
                  { //¿Tiene plan contratado?
                    user.company.monthlySubscriptionStatus == "active"
                    ? user.plan.extraLeads>0 //Mostrar alert: ExtraLeads o Comprar leads
                        ? <CAlert color="danger" className="alertNoLeads">{t('header.no-plan-leads-yes-extra', {number: user.plan.extraLeads})}</CAlert>
                        : <CAlert color="danger" className="alertNoLeads">{t('header.no-remaining-leads')} <b className="isLink underline" onClick={buyProduct}>{t('header.buy-leads')}</b></CAlert>
                    : //Mostrar alert: Comprar plan
                      <CAlert color="danger" className="alertNoLeads">{t('header.no-plan')} <b className="isLink underline" onClick={buyPlan}>{t('header.buy-plan')}</b></CAlert>
                  }
                </CNavItem>
              //END percentage>0 ?
              }
            </CHeaderNav>
            : ''
          //END user.role === 'user'
          }
        <CHeaderNav className="ms-auto me-2">
          <CNavItem className="navitemWelcome">
            <p><b>{t('header.welcome')} |</b> {user.company.name}</p>
          </CNavItem>
        </CHeaderNav>
      </CContainer>
    </CHeader>
    : ''
  }</>
  )
}

export default AppHeader;