import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CContainer, CSpinner } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { DateUtils } from '../../utils/DateUtils';
import PageNotFound from '../../pages/PageNotFound';

// routes config
import routes from '../../routes/routes'
import Panel from '../../pages/Panel';
import MyLeads from '../../pages/MyLeads';
import ConnectedMyAccount from '../../pages/MyAccount';

const AppContent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.auth.user);
  const logged = useSelector((state: any) => state.auth.loggedIn);

  useEffect(() => {
    console.log('Detecting loggin change: ', logged)
    const localLogged = localStorage.getItem('logged');
    //Check if must show billing warning
    if( !logged && !localLogged || localLogged == 'false' ) {
      navigate("/login");
    }
  }, [logged])

  useEffect(() => {
    //Check if must show billing warning
    if( user && user.company ) {
      if( !user.company.cif ) {
        dispatch({ type: 'billing', warningBilling: true });
      }
    }
  }, [])

  const today = DateUtils.getDate(new Date());

  return (
    <CContainer lg>
      <div className="d-flex flex-row-reverse mt-3">
        <div>
          <h4 className="d-flex flex-row-reverse today-subheader">Hoy</h4>
          <p>{t('header.today-date', { day: today.day, month: today.month, year: today.year })}</p>
        </div>
      </div>
      <Suspense fallback={<div className="d-flex justify-content-center"><CSpinner color="warning" /></div>}>
        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  element={<route.element />}
                />
              )
            )
          })}
          <Route path="/my-leads" element={ <MyLeads /> } />
          <Route path="/panel" element={ <Panel /> } />
          <Route path="/my-account" element={ <ConnectedMyAccount /> } />
          <Route path="*" element={ <PageNotFound /> } />
        </Routes>
      </Suspense>
      
    </CContainer>
  )
}

export default AppContent;