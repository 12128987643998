import { LOGIN_SUCCESS, LOGOUT_REQUEST, ME_SUCCESS } from "../contexts/actions/userActions";
import { LOGIN_REQUEST, REGISTER_SUCCESS } from "../contexts/actionTypes";
import store from "../contexts/store";
import { anonApiCall, authApiCall } from "./apiCalls";

/** Realiza el login mediante username/password */
export const loginCall = (username: string, password: string): Promise<any> => {
	return new Promise((resolve, reject) => {
		const params = {
			email: username,
			password
		};
		if (!username || !password) {
			reject('Username and password are required');
		}
		store.dispatch({type: LOGIN_REQUEST.type});
		anonApiCall.post("/user/auth/signin", params, {
			//AxiosRequestConfig parameter
			withCredentials: true //correct
		  })
			.then(async ({ data, status }) => {
				store.dispatch({type: LOGIN_SUCCESS.type, payload: data});
				resolve(data);
			})
			.catch((error) => { reject(processError(error)); })
	});
}

export const registerCall = (
	username: string, 
	password: string, 
	name: string,
	companyName: string,
	selectedBrands: string[],
	cpValue: string
	): Promise<any> => {
	return new Promise((resolve, reject) => {
		const params = {
			email: username,
			password,
			name,
			companyName,
			selectedBrands,
			cpValue
		};

		if (!username || !password || !name) {
			reject('Username and password are required');
		}
		anonApiCall.post("/user/auth/signup", params, {
			withCredentials: true //correct
		  })
			.then(async ({ data, status }) => {
				store.dispatch({type: REGISTER_SUCCESS.type});
				resolve(data);
			})
			.catch((error) => { reject(processError(error)); })
	});
}

export const meCall = (): Promise<any> => {
	return new Promise((resolve, reject) => {
		authApiCall.get("/user/auth/me")
			.then(async ({ data, status }) => {
				console.log('WHOIAM : ', data);
				store.dispatch({type: ME_SUCCESS.type, payload: data});
				resolve(data);
			})
			.catch((error) => { reject(processError(error)); })
	});
}

export const updateCustomer = (
	name: string,
	email: string,
	street: string, 
	cif: string,
	cp: string,
	city: string,
	country: string,
	phone: string
): Promise<any> => {
	return new Promise((resolve, reject) => {
        const params = {
            name, 
			email,
			street, 
			cif,
			cp,
			city,
			country,
			phone
		};

		authApiCall.post("/stripe/customer", params)
			.then(({ data, status }) => {
				resolve(data);
			})
			.catch((error) => { reject(processError(error)); })
	});
}

export const logoutCall = (): Promise<any> => {
	return new Promise((resolve, reject) => {
		authApiCall.post("user/auth/signout")
			.then(async ({ data, status }) => {
				store.dispatch({type: LOGOUT_REQUEST.type});
				resolve(data);
			})
			.catch((error) => { reject(processError(error)); })
	});
}

export const logoutImpersonate = (): Promise<any> => {
	return new Promise((resolve, reject) => {
		authApiCall.delete("user/impersonate")
			.then(async ({ data, status }) => {
				resolve(data);
			})
			.catch((error) => { reject(processError(error)); })
	});
}

export const updateSystem = (
	varName: string,
	value: any
): Promise<any> => {
	const params = {
        varName,
        value
    };

	return new Promise((resolve, reject) => {
		authApiCall.post("admin/system/vars", params)
			.then(async ({ data, status }) => {
				resolve(data);
			})
			.catch((error) => { reject(processError(error)); })
	});
}

export const getSystem = (): Promise<any> => {

	return new Promise((resolve, reject) => {
		authApiCall.get("admin/system/vars")
			.then(async ({ data, status }) => {
				resolve(data);
			})
			.catch((error) => { reject(processError(error)); })
	});
}

export const refreshTokenCall = async (): Promise<any> => {
	let username, password;
	try {
		username = await localStorage.getItem('@username');
		password = await localStorage.getItem('@password');
	} catch (error) {
		username = '';
		password = '';
	}
	
	return new Promise((resolve, reject) => {
		const params = {
			username,
			password
		};
		console.log(`Refreshing auth token with username: ${username} / password: ${password}`)
		anonApiCall.get("/auth/refresh", { params })
			.then(({ data, status }) => {
				resolve(data);
			})
			.catch((error) => { reject(processError(error)); })
	});
}

const processError = (error: any) => {
    if (error.response) {
        const serverError = error.response.data;
        if(serverError.statusCode === 403) {
            store.dispatch({type: LOGOUT_REQUEST.type});
            return 'error.0003';
        }
		else if(serverError.statusCode === 500) {
            return 'error.0001';
        }
		else if(serverError.statusCode === 400) {
            return 'error.'+serverError.message;
        }
        return serverError.message;
    } else if (error.request) {
        return 'error.0002';
    } else {
        return 'error.0001';
    }
}