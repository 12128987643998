import { CImage } from '@coreui/react'
import imgContacts from '../assets/images/circle-contacts.png'
import imgNoContacts from '../assets/images/circle.png'

export function ContactsRate ({ contacts }) {

    const getRateType = ( contacts ) => {
        switch(contacts) {
            case 1:
                return <>
                    <CImage src={imgContacts} className="_pe-05" />
                    <CImage src={imgNoContacts} className="_pe-05" />
                    <CImage src={imgNoContacts} className="_pe-05" />
                    <CImage src={imgNoContacts} />
                </>;
            case 2:
                return <>
                    <CImage src={imgContacts} className="_pe-05" />
                    <CImage src={imgContacts} className="_pe-05" />
                    <CImage src={imgNoContacts} className="_pe-05" />
                    <CImage src={imgNoContacts} />
                </>;
            case 3:
                return <>
                    <CImage src={imgContacts} className="_pe-05" />
                    <CImage src={imgContacts} className="_pe-05" />
                    <CImage src={imgContacts} className="_pe-05" />
                    <CImage src={imgNoContacts} />
                </>;
            case 4:
            case 5:
                return <>
                    <CImage src={imgContacts} className="_pe-05" />
                    <CImage src={imgContacts} className="_pe-05" />
                    <CImage src={imgContacts} className="_pe-05" />
                    <CImage src={imgContacts} />
                </>;
            default:
                return <>
                    <CImage src={imgNoContacts} className="_pe-05" />
                    <CImage src={imgNoContacts} className="_pe-05" />
                    <CImage src={imgNoContacts} className="_pe-05" />
                    <CImage src={imgNoContacts} />
                </>;
        }
    }

    return (
        <>
            {getRateType(contacts)}
        </>
    )
}